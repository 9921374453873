import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "./actions";

interface SnackbarState {
  open: boolean;
  message: string;
  severity: "info" | "warning" | "error" | "success";
}

const initialState: SnackbarState = {
  open: false,
  message: "",
  severity: "info",
};

const snackbarReducer = createReducer(initialState, (builder) => {
  // @ts-ignore
  builder
    .addCase(
      SHOW_SNACKBAR,
      (
        state,
        action: PayloadAction<{
          message: string;
          severity: "info" | "warning" | "error" | "success";
        }>
      ) => {
        state.open = true;
        state.message = action.payload.message;
        state.severity = action.payload.severity;
      }
    )
    .addCase(HIDE_SNACKBAR, (state) => {
      state.open = false;
      state.message = "";
    });
});

export default snackbarReducer;

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@Store';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import qs from 'qs';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const parseQuery = (search: string): any => qs.parse(search, {
  ignoreQueryPrefix: true,
});

export const useQueryParams = (): any => {
  const { search } = useLocation();
  const [params, setParams] = useState(parseQuery(search));

  useEffect(() => {
    setParams(parseQuery(search));
  }, [search]);

  return params;
};

export const useWindowSize = (): any => {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }), [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(():any => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isClient, getSize]);

  return windowSize;
};

import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import MainComponent from "@src/MainComponent";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@hooks";
import { RootState } from "@Store";
import {
  selectCurrentOrganization,
  selectOrganizationPolicyData,
} from "@Store/Organizations/selectors";
import { getOrganizationPolicyRequest } from "@Store/Organizations/actions";
import PrivacyTab from "@Pages/CompanyLegal/privatcyTab";

const CompanyLegalPage: React.FC = () => {
  const { t } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState("privacy");
  const dispatch = useAppDispatch();

  const { currentOrganization, policyData } = useAppSelector(
    (state: RootState) => ({
      currentOrganization: selectCurrentOrganization(state),
      policyData: selectOrganizationPolicyData(state),
    })
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTabKey(newValue);
  };

  useEffect(() => {
    if (currentOrganization?.orgId) {
      dispatch(
        getOrganizationPolicyRequest({
          orgId: currentOrganization.orgId,
          type: activeTabKey,
        })
      );
    }
  }, [dispatch, currentOrganization, activeTabKey]);

  return (
    <Box className="root">
      <div className="page-container">
        <Tabs value={activeTabKey} onChange={handleChange}>
          <Tab label={t("sidebar.organization.privacy")} value="privacy" />
          <Tab label={t("sidebar.organization.terms")} value="gtac" />
        </Tabs>
        {activeTabKey === "privacy" && (
          <PrivacyTab
            type={"PRIVACY"}
            currentOrganization={currentOrganization}
            policyData={policyData}
          />
        )}
        {activeTabKey === "gtac" && (
          <PrivacyTab
            type={"GTAC"}
            currentOrganization={currentOrganization}
            policyData={policyData}
          />
        )}
      </div>
    </Box>
  );
};

export default CompanyLegalPage;

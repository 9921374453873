import React from "react";
import { Toolbar, Typography, IconButton, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";

interface CustomToolbarProps {
  numSelected: number;
  onDelete: () => void;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  numSelected,
  onDelete,
}) => {
  const label = { inputProps: { "aria-label": "Activate users" } };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        bgcolor: "'#4C4C4C'",
        color: "white",
        display: numSelected > 0 ? "flex" : "none",
        justifyContent: "space-between",
      }}
    >
      <Typography
        color="inherit"
        variant="subtitle1"
        sx={{ marginRight: "140px" }}
      >
        {numSelected} selected
      </Typography>
      <div>
        <Switch {...label} defaultChecked /> <span>Activate</span>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: "25px",
            borderRadius: "4px",
            marginRight: "10px",
            marginLeft: "30px",
          }}
        >
          Enter
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ height: "25px", borderRadius: "4px" }}
        >
          Enter
        </Button>
      </div>
    </Toolbar>
  );
};

export default CustomToolbar;

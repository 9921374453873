import { getAuthRequest } from "@API";
import {
  createPostAxiosTokenRefreshInterceptors,
  createPostLoginRequestInterceptors,
} from "@API/App/middlewares";
import {
  ApiAuthenticationResponse,
  BodyData,
  IPostAxiosTokenRefresh,
  IPostLoginRequest,
} from "@API/App/types";
import { ILoginData } from "@Store/App/types";
import { AxiosInstance, AxiosResponse } from "axios";

export const postLoginRequest = async (
  payload: ILoginData
): Promise<IPostLoginRequest> => {
  const body: BodyData = {
    email: payload.username,
    password: payload.password,
  };

  const authRequest: AxiosInstance = getAuthRequest();

  createPostLoginRequestInterceptors(authRequest);

  const response: AxiosResponse = await authRequest.post("/token", body);

  const tokenData: ApiAuthenticationResponse = response.data;

  return {
    data: {
      access_token: tokenData.access_token,
      refresh_token: tokenData.refresh_token,
      token_type: tokenData.token_type,
    },
  } as IPostLoginRequest;
};

export const postAxiosTokenRefresh =
  async (): Promise<IPostAxiosTokenRefresh> => {
    const body: BodyData = {
      refresh_token: localStorage.getItem("refreshToken") ?? "",
    };

    const authRequest: AxiosInstance = getAuthRequest();

    createPostAxiosTokenRefreshInterceptors(authRequest);

    const response: AxiosResponse = await authRequest.post(
      "/refresh-token",
      body
    );

    const refreshedTokenData: ApiAuthenticationResponse = response.data;

    return {
      data: {
        access_token: refreshedTokenData.access_token,
        refresh_token: refreshedTokenData.refresh_token,
        token_type: refreshedTokenData.token_type,
      },
      error: response.status,
    } as IPostAxiosTokenRefresh;
  };

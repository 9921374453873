// src/App.jsx

import "./App.scss";
import "./Index.less";

import { selectLanguage } from "@Store/App/selectors";
import { useAppSelector } from "@hooks";
import MainRouter from "./Router";
import moment from "moment";
import React, { useEffect } from "react";
import SnackbarComponent from "@Components/SnackBarComponent";

function App() {
  const selectedLanguage = useAppSelector(selectLanguage);

  useEffect(() => {
    moment.locale(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <>
      <MainRouter />
    </>
  );
}

export default App;

import { lang } from "@Store/App/types";
import { IOrganizationUser } from "@Store/Organizations/types";

/**
 * Detects the language of the browser and returns it.
 * @returns {lang} The language of the browser.
 * @example
 * const language: lang = detectLanguage();
 *
 * console.log(language); // "en" | "de"
 */
export const detectLanguage = (): lang => {
  if (window.navigator.language.startsWith("en" as lang)) {
    return "en";
  } else if (window.navigator.language.startsWith("de" as lang)) {
    return "de";
  } else {
    return "de";
  }
};

type UserNameArgs = Pick<
  IOrganizationUser,
  "givenName" | "surName" | "nickName" | "displayName" | "emailAddress"
>;

export const getUserName = (
  {
    givenName = "",
    surName = "",
    nickName = "",
    displayName = "",
    emailAddress = "",
  }: Partial<UserNameArgs> = {} as UserNameArgs
): string => {
  return (
    `${givenName} ${surName}`.trim() ??
    nickName ??
    displayName ??
    emailAddress ??
    ""
  );
};

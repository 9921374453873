import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Switch,
} from "@mui/material";
import "./styles.scss";
import CustomTable, { Column, Data } from "@Components/Table";
import AddPlusIcon from "@src/Constants/svg/AddPlusIcon";
import RoleComponent, { Role } from "@Components/Header/LabelRoleIndicator";
import { useAppSelector } from "@hooks";
import { RootState } from "@Store";
import { useTranslation } from "react-i18next";
import { selectFilteredOrganizations } from "@Store/AppUser/selectors";
import { selectCurrentOrganization } from "@Store/Organizations/selectors";
import { useDispatch } from "react-redux";
import { usersListRequest } from "@Store/Users/actions";
import CreateEditUserModal from "@Components/CreateEditUserModal/create-edit-user-modal";
import ConfirmationModal from "@Components/ConfirmationModel/confirmation-model";
import { toggleUserActiveStatus } from "@Store/Organizations/actions";
import ModalBulkUploadUsers from "@Components/ModalBulkUploadUsers/modal-bulk-upload-users";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";

export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  borderRadius: "4px",
  height: "38px",
  width: "340px",
  fontSize: "14px",
  border: "1px solid #4C4C4C",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #4C4C4C",
    },
    "&:hover fieldset": {
      border: "1px solid #4C4C4C",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #4C4C4C",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.common.white,
  },
  "& .MuiInputAdornment-root": {
    marginRight: 0,
    marginLeft: theme.spacing(1),
  },
  "& .MuiIconButton-root": {
    color: theme.palette.common.white,
  },
}));

const UsersManagementPage: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [newStatus, setNewStatus] = useState<string>("");
  const [initialData, setInitialData] = useState<any>({});
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setInitialData({});
    setOpen(false);
  };
  const dispatch = useDispatch();
  const [isBulkUploadModalVisible, setIsBulkUploadModalVisible] =
    useState(false); // State for Bulk Upload Modal

  const columns: Column[] = [
    {
      field: "firstname",
      headerName: t("organizationUsers.columns.firstname"),
    },
    { field: "lastname", headerName: t("organizationUsers.columns.lastname") },
    {
      field: "description",
      headerName: t("organizationUsers.columns.description"),
    },
    { field: "nickname", headerName: t("organizationUsers.columns.nickname") },
    {
      field: "department",
      headerName: t("organizationUsers.columns.department"),
    },
    { field: "email", headerName: t("organizationUsers.columns.email") },
    {
      field: "role",
      headerName: t("organizationUsers.columns.role"),
      sortable: false,
    },
    { field: "active", headerName: t("organizationUsers.columns.active") },
    {
      field: "status",
      headerName: t("organizationUsers.columns.status"),
      sortable: false,
    },
  ];

  const { currentOrganization, users } = useAppSelector((state: RootState) => ({
    currentOrganization: selectCurrentOrganization(state),
    organizationList: selectFilteredOrganizations(state),
    users: state.users.general.list,
  }));

  useEffect(() => {
    if (currentOrganization?.orgId) {
      dispatch(
        usersListRequest(
          currentOrganization?.orgId
            ? currentOrganization?.orgId
            : sessionStorage.getItem("selectedOrganization") || ""
        )
      );
    }
  }, [currentOrganization]);

  const handleSearch = useCallback(
    _.debounce((query) => {
      setSearchQuery(query);
    }, 300),
    []
  );

  const transformRole = (role: string) => {
    switch (role) {
      case "SIMPLE_USER":
        return <RoleComponent role={Role.USER} />;
      case "ORG_ADMIN":
        return <RoleComponent role={Role.ADMIN} />;
      case "SYSTEM_ADMIN":
        return <RoleComponent role={Role.SYSTEM_ADMIN} />;
      case "SUPPORT_USER":
        return <RoleComponent role={Role.SUPPORT_USER} />;
      default:
        return <RoleComponent role={Role.USER} />;
    }
  };

  const transformStatus = (status: string, user: any) => {
    return status !== "INVITED" ? (
      <Switch
        checked={status === "ACTIVE"}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCurrentUser(user);
          setConfirmationOpen(true);
          setNewStatus(status === "ACTIVE" ? "INACTIVE" : "ACTIVE");
        }}
      />
    ) : null;
  };

  const data: any[] = users
    .filter((user) =>
      `${user.givenName} ${user.surName} ${user.description} ${user.department} ${user.nickName} ${user.emailAddress} ${user.role}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .map((user) => ({
      id: user.userId,
      firstname: user.givenName,
      lastname: user.surName,
      position: user.description || "N/A",
      department: user.department || "N/A",
      description: user.description,
      nickname: user.nickName,
      email: user.emailAddress,
      role: transformRole(user.role),
      active: user.status,
      status: transformStatus(user.status, user),
    }));

  const handleConfirm = () => {
    dispatch(
      toggleUserActiveStatus({
        org_id: currentUser.orgId,
        user_id: currentUser.userId,
        isActive: currentUser.status === "ACTIVE",
      })
    );
    setConfirmationOpen(false);
  };

  const handleCancel = () => {
    setConfirmationOpen(false);
  };

  const showBulkUploadModal = () => {
    setIsBulkUploadModalVisible(true);
  };

  const hideBulkUploadModal = () => {
    setIsBulkUploadModalVisible(false);
  };

  const menuItems = [
    {
      name: t("buttons.edit"),
      onClick: (row: Data) => {
        setInitialData(
          users.find((i) => i.userId.toString() === row.id.toString())
        );
        handleOpen();
      },
    },
  ];

  return (
    <Box className="root">
      <div className="clients-organisation-header">
        <h2>{t("organizationUsers.title")}</h2>
        <div>
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: "4px",
            }}
            onClick={showBulkUploadModal}
          >
            <AddPlusIcon />{" "}
            <span
              style={{ marginLeft: "10px", color: "#000", fontWeight: "600" }}
            >
              {t("organizationUsers.buttons.addBulk")}
            </span>
          </Button>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          marginBottom: "20px",
        }}
      >
        <StyledOutlinedInput
          id="outlined-adornment-search"
          type={"text"}
          placeholder="Search..."
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="search icon" edge="start">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => handleSearch(e.target.value)}
          label="Search"
        />
      </div>
      <CustomTable
        columns={columns.map((col) => ({
          ...col,
          headerName: t(`organizationUsers.columns.${col.field}`),
        }))}
        data={data}
        rowsPerPage={10}
        menuItems={menuItems}
        selectable={false}
      />

      <ModalBulkUploadUsers
        visible={isBulkUploadModalVisible}
        onClose={hideBulkUploadModal}
      />
      <CreateEditUserModal
        open={open}
        handleClose={handleClose}
        initialData={initialData}
      />
      <ConfirmationModal
        key={confirmationOpen + currentUser + newStatus}
        open={confirmationOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={t("organizationUsers.modals.confirmTitle")}
        content={
          newStatus === "ACTIVE"
            ? t("organizationUsers.modals.confirmContentActivate", {
                name: currentUser?.givenName,
              })
            : t("organizationUsers.modals.confirmContentDeactivate", {
                name: currentUser?.givenName,
              })
        }
      />
    </Box>
  );
};

export default UsersManagementPage;

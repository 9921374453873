import {
  combineReducers,
  createReducer,
  PayloadAction,
} from "@reduxjs/toolkit";
import { detectLanguage } from "@src/Utilities";
import {
  loginFail,
  loginRequest,
  loginSuccess,
  logoutFail,
  logoutRequest,
  logoutSuccess,
  setLanguage,
  setSideBarCollapsed,
  toggleSideBarCollapsed,
} from "./actions";
import { lang } from "./types";

// TODO: add data with tokens
interface ILoginState {
  isLoading: boolean;
  success: boolean;
  fail: boolean;
}

const LOGIN_INITIAL_STATE: ILoginState = {
  isLoading: false,
  success: false,
  fail: false,
};

const loginReducer = createReducer(LOGIN_INITIAL_STATE, {
  [loginRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [loginSuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [loginFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),
});

const logoutReducer = createReducer(LOGIN_INITIAL_STATE, {
  [logoutRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [logoutSuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [logoutFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),
});

interface IGeneralState {
  language: lang;
  sideBarCollapsed: boolean;
}

const GENERAL_INITIAL_STATE: IGeneralState = {
  language: detectLanguage(),
  sideBarCollapsed: true,
};

const generalReducer = createReducer(GENERAL_INITIAL_STATE, {
  [setLanguage.type]: (state, { payload }: PayloadAction<lang>) => ({
    ...state,
    language: payload,
  }),
  [setSideBarCollapsed.type]: (state, { payload }: PayloadAction<boolean>) => ({
    ...state,
    sideBarCollapsed: payload,
  }),
  [toggleSideBarCollapsed.type]: (state) => ({
    ...state,
    sideBarCollapsed: !state.sideBarCollapsed,
  }),
});

export interface IAppState {
  readonly login: ILoginState;
  readonly logout: ILoginState;
  readonly general: IGeneralState;
}

export default combineReducers<IAppState>({
  login: loginReducer,
  logout: logoutReducer,
  general: generalReducer,
});

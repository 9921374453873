import { RootState } from "@Store";
import { UserInvitationFormValues } from "@Store/Invites/types";
import { IOrganizationUser } from "@Store/Organizations/types";

export const formatUserData = (
  data: IOrganizationUser & { presenceDescription?: string }
) => {
  const {
    orgId,
    userId,
    role,
    givenName,
    surName,
    nickName,
    description,
    department,
    emailAddress,
    mobileNumber,
    presence,
    presenceDescription,
  } = data;

  return {
    orgId,
    userId,
    role,
    ...(givenName !== "" && { givenName }),
    ...(surName !== "" && { surName }),
    ...(nickName !== null && { nickName }),
    ...(description !== "" && { description }),
    ...(department !== "" && { department }),
    ...(emailAddress !== "" && { emailAddress }),
    ...(mobileNumber !== "" && { mobileNumber }),
    presenceType: presence,
    ...(presenceDescription !== null && { presenceDescription }),
  };
};
export const selectEditAddUserModalValues = (state: RootState) => {
  const { user } = state.users.general.userModal;

  if (user) return user;
};

export const formatUserInvitationData = (
  data: UserInvitationFormValues
): UserInvitationFormValues => {
  const { bpUserName, validUntil }: UserInvitationFormValues = data;

  return {
    bpUserName,
    ...(validUntil !== undefined && { validUntil: new Date(validUntil) }),
  };
};

export const selectInviteUserModalValues = (state: RootState) => {
  const { invite } = state.invites.general.inviteUserModal;

  if (invite) return invite;
};

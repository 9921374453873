import {
  getAxiosAdminInvitationsRequest,
  getAxiosAdminOrganizationRequest,
  postAxiosAdminRequest,
} from "@API";
import {
  createGetInvitationDetailsRequestInterceptors,
  createGetOrganizationInvitedUsersRequestInterceptors,
  createPostInviteUserRequestInterceptors,
} from "@API/Invites/middlewares";
import {
  IAxiosInviteUser,
  IGetAxiosInvitationDetails,
  IGetAxiosOrganizationInvitedUsers,
} from "@API/Invites/types";
import { parseDateStringToDate } from "@API/Utilities";
import {
  IAPIUserInvite,
  IDeleteUserInviteRequest,
  INotifyUserInviteRequest,
  IUserInvite,
  InvitationId,
  PostInviteUserBody,
} from "@Store/Invites/types";
import { OrganizationId } from "@Store/Organizations/types";
import { AxiosInstance, AxiosResponse } from "axios";

export const getAxiosOrganizationInvitedUsers = async (
  orgId: OrganizationId
): Promise<IGetAxiosOrganizationInvitedUsers> => {
  const organizationInvitedUsersRequest: AxiosInstance =
    getAxiosAdminOrganizationRequest();

  createGetOrganizationInvitedUsersRequestInterceptors(
    organizationInvitedUsersRequest
  );

  const response: AxiosResponse = await organizationInvitedUsersRequest.get(
    `${orgId}/invitations`
  );

  const organizationInvitedUsersData: IAPIUserInvite[] = response.data;

  const parsedOrganizationInvitedUsersData: IUserInvite[] =
    organizationInvitedUsersData.map(
      (apiUserInvite: IAPIUserInvite): IUserInvite => ({
        ...apiUserInvite,
        invitedOrgUserGivenName: apiUserInvite.invitedOrgUserGivenName ?? "",
        invitedOrgUserSurname: apiUserInvite.invitedOrgUserSurname ?? "",
        invitedOrgUserUsername: apiUserInvite.invitedOrgUserUsername ?? "",
        invitedByUsername: apiUserInvite.invitedByUsername ?? "",
        creationTs: parseDateStringToDate(apiUserInvite.creationTs),
        validUntil: parseDateStringToDate(apiUserInvite.validUntil),
        revocationTs:
          apiUserInvite.revocationTs === null
            ? apiUserInvite.revocationTs
            : parseDateStringToDate(apiUserInvite.revocationTs),
        getOtpValidUntil:
          apiUserInvite.getOtpValidUntil === null
            ? apiUserInvite.getOtpValidUntil
            : parseDateStringToDate(apiUserInvite.getOtpValidUntil),
        acceptationTs:
          apiUserInvite.acceptationTs === null
            ? apiUserInvite.acceptationTs
            : parseDateStringToDate(apiUserInvite.acceptationTs),
      })
    );

  return { data: parsedOrganizationInvitedUsersData };
};

export const postAxiosInviteUser = async (
  body: PostInviteUserBody
): Promise<IAxiosInviteUser> => {
  const inviteUserRequest: AxiosInstance = postAxiosAdminRequest();

  createPostInviteUserRequestInterceptors(inviteUserRequest);

  const response: AxiosResponse = await inviteUserRequest.post(
    "/invitations",
    body
  );

  const userInvitationData: IAPIUserInvite = response.data;

  const parsedUserInvitationData: IUserInvite = {
    ...userInvitationData,
    invitedOrgUserGivenName: userInvitationData.invitedOrgUserGivenName ?? "",
    invitedOrgUserSurname: userInvitationData.invitedOrgUserSurname ?? "",
    invitedOrgUserUsername: userInvitationData.invitedOrgUserUsername ?? "",
    invitedByUsername: userInvitationData.invitedByUsername ?? "",
    creationTs: parseDateStringToDate(userInvitationData.creationTs),
    validUntil: parseDateStringToDate(userInvitationData.validUntil),
    revocationTs:
      userInvitationData.revocationTs === null
        ? userInvitationData.revocationTs
        : parseDateStringToDate(userInvitationData.revocationTs),
    getOtpValidUntil:
      userInvitationData.getOtpValidUntil === null
        ? userInvitationData.getOtpValidUntil
        : parseDateStringToDate(userInvitationData.getOtpValidUntil),
    acceptationTs:
      userInvitationData.acceptationTs === null
        ? userInvitationData.acceptationTs
        : parseDateStringToDate(userInvitationData.acceptationTs),
  };

  return { data: parsedUserInvitationData };
  // return { data: { ...body, id: Math.floor(Math.random() * 100000000) } };
};

export const getAxiosInvitationDetails = async (
  invitationId: InvitationId
): Promise<IGetAxiosInvitationDetails> => {
  const invitationDetailsRequest: AxiosInstance =
    getAxiosAdminInvitationsRequest();

  createGetInvitationDetailsRequestInterceptors(invitationDetailsRequest);

  const response: AxiosResponse = await invitationDetailsRequest.get(
    `/invitations/${invitationId}/details`
  );

  const invitationDetailsData: IAPIUserInvite = response.data;

  const parsedInvitationDetailsData: IUserInvite = {
    ...invitationDetailsData,
    invitedOrgUserGivenName:
      invitationDetailsData.invitedOrgUserGivenName ?? "",
    invitedOrgUserSurname: invitationDetailsData.invitedOrgUserSurname ?? "",
    invitedOrgUserUsername: invitationDetailsData.invitedOrgUserUsername ?? "",
    invitedByUsername: invitationDetailsData.invitedByUsername ?? "",
    creationTs: parseDateStringToDate(invitationDetailsData.creationTs),
    validUntil: parseDateStringToDate(invitationDetailsData.validUntil),
    revocationTs:
      invitationDetailsData.revocationTs === null
        ? invitationDetailsData.revocationTs
        : parseDateStringToDate(invitationDetailsData.revocationTs),
    getOtpValidUntil:
      invitationDetailsData.getOtpValidUntil === null
        ? invitationDetailsData.getOtpValidUntil
        : parseDateStringToDate(invitationDetailsData.getOtpValidUntil),
    acceptationTs:
      invitationDetailsData.acceptationTs === null
        ? invitationDetailsData.acceptationTs
        : parseDateStringToDate(invitationDetailsData.acceptationTs),
  };

  return { data: parsedInvitationDetailsData };
};

export const putAxiosInviteUserNotify = async ({
  orgId,
  body,
}: INotifyUserInviteRequest): Promise<{}> => {
  // const response = await getApiRequest().post(`/organizations/${orgId}/organization-users-invite/notify/`, formatBody(body));

  return {};
};

export const deleteAxiosInviteUser = async ({
  orgId,
  body,
}: IDeleteUserInviteRequest): Promise<{}> => {
  // TODO: api call
  // const response = await getApiRequest().delete(`/organizations/${orgId}/organization-users-invite/`, , formatBody(body));

  return {};
};

import {
  combineReducers,
  createReducer,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  IOrganizationUser,
  OrganizationUserId,
} from "@Store/Organizations/types";
import {
  createUserFail,
  createUserRequest,
  createUserSuccess,
  deleteUserFail,
  deleteUserRequest,
  deleteUserSuccess,
  resetUserModal,
  setUserModal,
  setUserModalType,
  updateUserFail,
  updateUserRequest,
  updateUserSuccess,
  usersListFail,
  usersListRequest,
  usersListSuccess,
} from "./actions";
import { IUserModal, IUserModalType } from "./types";
import {toggleUserActiveStatusSuccess} from "@Store/Organizations/actions";

interface IAppUserInitialState {
  list: IOrganizationUser[];
  userModal: IUserModal;
  isLoading: boolean;
  success: boolean;
  fail: boolean;
}

const APP_USER_INITIAL_STATE: IAppUserInitialState = {
  list: [],
  userModal: {
    user: undefined,
    type: undefined,
    isLoading: false,
    success: false,
    fail: false,
  },
  isLoading: false,
  success: false,
  fail: false,
};

const usersListReducer = createReducer(APP_USER_INITIAL_STATE, {
  [usersListRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [usersListSuccess.type]: (
    state,
    { payload }: PayloadAction<IOrganizationUser[]>
  ) => ({
    ...state,
    list: payload,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [usersListFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),

  [deleteUserRequest.type]: (state) => ({
    ...state,
    userModal: {
      ...state.userModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [deleteUserSuccess.type]: (
    state,
    { payload }: PayloadAction<OrganizationUserId>
  ) => {
    const index = state.list.findIndex((item) => item.userId === payload);
    const newList = [...state.list];
    if (index !== -1) newList.splice(index, 1);

    return {
      ...state,
      list: newList,
      userModal: {
        ...state.userModal,
        isLoading: false,
        success: true,
        fail: false,
      },
    };
  },
  [deleteUserFail.type]: (state) => ({
    ...state,
    userModal: {
      ...state.userModal,
      isLoading: false,
      success: false,
      fail: true,
    },
  }),

  [updateUserRequest.type]: (state) => ({
    ...state,
    userModal: {
      ...state.userModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [updateUserSuccess.type]: (
    state,
    { payload }: PayloadAction<IOrganizationUser>
  ) => {
    const { list, userModal } = state;

    const newOrganizationUsersList = list.map((organizationUser) =>
      organizationUser.userId === payload.userId
        ? { ...organizationUser, ...payload }
        : organizationUser
    );

    return {
      ...state,
      list: newOrganizationUsersList,
      userModal: {
        ...userModal,
        isLoading: false,
        success: true,
        fail: false,
      },
    };
  },
  [updateUserFail.type]: (state) => {
    return {
      ...state,
      userModal: {
        ...state.userModal,
        isLoading: false,
        success: false,
        fail: true,
      },
    }
  },

  [createUserRequest.type]: (state) => ({
    ...state,
    userModal: {
      ...state.userModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [createUserSuccess.type]: (
    state,
    { payload }: PayloadAction<IOrganizationUser>
  ) => ({
    ...state,
    list: [payload, ...state.list],
    userModal: {
      ...state.userModal,
      isLoading: false,
      success: true,
      fail: false,
    },
  }),
  [createUserFail.type]: (state) => ({
    ...state,
    userModal: {
      ...state.userModal,
      isLoading: false,
      success: false,
      fail: true,
    },
  }),

  [setUserModalType.type]: (
    state,
    { payload }: PayloadAction<IUserModalType>
  ) => {
    return {
      ...state,
      userModal: {
        ...state.userModal,
        type: payload,
      },
    };
  },
  [setUserModal.type]: (
    state,
    { payload }: PayloadAction<IOrganizationUser>
  ) => {
    return {
      ...state,
      userModal: {
        ...state.userModal,
        user: payload,
      },
    };
  },
  [resetUserModal.type]: (state) => ({
    ...state,
    userModal: {
      user: undefined,
      type: undefined,
      isLoading: false,
      success: false,
      fail: false,
    },
  }),
  [toggleUserActiveStatusSuccess.type]: (state, action) => {
    const { user_id, isActive } = action.payload;
    // @ts-ignore
    state.list = state.list.map(user =>
        user.userId === user_id ? { ...user, status: !isActive ? 'ACTIVE' : 'INACTIVE' } : user
    );
  },
});

export interface IGeneralState {
  readonly general: IAppUserInitialState;
}

export default combineReducers<IGeneralState>({
  general: usersListReducer,
});

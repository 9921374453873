import { getAppUserRequest, getAxiosAdminOrganizationRequest } from "@API";
import { createGetAppUserInterceptors } from "@API/AppUser/middlewares";
import { IGetAxiosAppUser } from "@API/AppUser/types";
import { IAppUser } from "@Store/AppUser/types";
import { AxiosInstance, AxiosResponse } from "axios";

export const getAxiosAppUser = async (): Promise<IGetAxiosAppUser> => {
  const apiRequest: AxiosInstance = getAppUserRequest();

  createGetAppUserInterceptors(apiRequest);

  const response: AxiosResponse = await apiRequest.get("/main-user");

  const userData: IAppUser = response.data;

  if (userData.emailAddress !== localStorage.getItem("emailAddress")) {
    return {
      error: `The email address of the main-user does not match the email address of the logged-in user. ${response.status}`,
    } as IGetAxiosAppUser;
  }

  return { data: userData };
};

export const postAxiosUploadCsvFile = async (
  orgId: string,
  csvContent: string
): Promise<string> => {
  const fileUploadRequest: AxiosInstance = getAxiosAdminOrganizationRequest();
  const response: AxiosResponse = await fileUploadRequest.post(
    `/${orgId}/users/bulk`,
    csvContent,
    {
      headers: { "Content-Type": "text/plain" },
    }
  );

  return response.data;
};

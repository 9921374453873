import React from "react";
import { Card, CardContent } from "@mui/material";
import "./styles.scss";

const ClientInfoCard: React.FC = () => (
  <Card className="single-organization-content-card">
    <div className="title">Client</div>
    <CardContent className="card-content">
      <div className="client-details">
        <div className="client-info">
          <span className="client-name">Koch LLC</span>
          <span className="client-address">
            Mr. Enrique Heathcote Torp Loaf st. 234 18999 Berlin Germany
          </span>
        </div>
        <div className="technical-contact">
          <span className="info-label">Technical contact:</span>
          <span className="contact-name">Danielle Stehr</span>
          <span className="contact-email">danielle.stehr@yahoo.com</span>
        </div>
      </div>
    </CardContent>
  </Card>
);

export default ClientInfoCard;

import { call, put, takeLatest } from "redux-saga/effects";
import {
  teamsListRequest,
  teamsListSuccess,
  teamsListFail,
  createTeamSuccess,
  createTeamFail,
  createTeamRequest,
  singleTeamFail,
  singleTeamRequest,
  singleTeamSuccess,
  updateTeamRequest,
  updateTeamSuccess,
} from "./actions";
import {
  getAxiosSingleTeamRequest,
  getAxiosTeamsRequest,
  postAxiosCreateTeamRequest,
  patchAxiosUpdateTeamRequest,
} from "@API/Teams/endpoints";
import { ICreateTeamPayload } from "@API/Teams/types";
import { showSnackbar } from "@Store/toast/actions";

function* onTeamsListRequest(action: { payload: string }): Generator {
  try {
    const response = yield call(getAxiosTeamsRequest, action.payload);
    // @ts-ignore
    yield put(teamsListSuccess(response.data));
  } catch (error) {
    yield put(teamsListFail(error as Error));
  }
}

function* onCreateTeamRequest(action: {
  payload: { orgId: string; payload: ICreateTeamPayload };
}): Generator {
  try {
    const response = yield call(
      postAxiosCreateTeamRequest,
      action.payload.orgId,
      action.payload.payload
    );

    // @ts-ignore
    yield put(createTeamSuccess(response.data));
    yield put(teamsListRequest(action.payload.orgId));
    yield put(showSnackbar("Team created successfully", "success"));
  } catch (error) {
    yield put(createTeamFail(error as Error));

    yield put(showSnackbar("Failed to create team", "error"));
  }
}

function* onUpdateTeamRequest(action: {
  payload: { orgId: string; payload: ICreateTeamPayload; teamId: string };
}): Generator {
  try {
    const response = yield call(
      patchAxiosUpdateTeamRequest,
      action.payload.teamId,
      action.payload.orgId,
      action.payload.payload
    );
    // @ts-ignore
    yield put(updateTeamSuccess(response.data));
    yield put(teamsListRequest(action.payload.orgId));
    yield put(showSnackbar("Team updated successfully", "success"));
  } catch (error) {
    yield put(createTeamFail(error as Error));

    yield put(showSnackbar("Failed to update team", "error"));
  }
}

function* onSingleTeamRequest(action: {
  payload: { orgId: string; id: string };
}): Generator {
  try {
    const response = yield call(
      getAxiosSingleTeamRequest,
      action.payload.orgId,
      action.payload.id
    );

    // @ts-ignore
    yield put(singleTeamSuccess(response.data));
  } catch (error) {
    yield put(singleTeamFail(error as Error));
  }
}

export default function* teamsSagas() {
  yield takeLatest(createTeamRequest, onCreateTeamRequest);
  yield takeLatest(updateTeamRequest, onUpdateTeamRequest);
  yield takeLatest(teamsListRequest, onTeamsListRequest);
  yield takeLatest(singleTeamRequest, onSingleTeamRequest);
}

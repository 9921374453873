import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { useAppSelector } from "@hooks";
import { useTranslation } from "react-i18next";
import { selectFilteredOrganizations } from "@Store/AppUser/selectors";

// @ts-ignore
const SelectOrganizationPage = ({ onOrgSelect }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { organizationList } = useAppSelector((state) => ({
    organizationList: selectFilteredOrganizations(state),
  }));

  const handleSelectOrganization = (orgId: any) => {
    onOrgSelect(orgId);
    history.push("/dashboard");
  };

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={{ color: "#fff" }}>{t("organization.title")}</h2>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          marginTop: "100px",
          marginLeft: "200px",
        }}
      >
        {organizationList.map((i) => {
          return (
            <div key={i.orgId}>
              <Button
                style={{
                  marginBlock: "10px",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #fff",
                  width: "300px",
                }}
                onClick={() => handleSelectOrganization(i.orgId)}
              >
                {i.orgLogoUrl && <img src={i.orgLogoUrl || ""} height={30} />}
                <h4 style={{ marginLeft: "15px", color: "#fff" }}>
                  {i.orgName}
                </h4>
              </Button>
            </div>
          );
        })}
      </div>
      <Button color="secondary"></Button>
    </div>
  );
};

export default SelectOrganizationPage;

import { createTheme, ThemeOptions } from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";
import { PaletteColorOptions } from "@mui/material";

// Extend the Palette interface to include tertiary
declare module "@mui/material/styles" {
  interface Palette {
    tertiary: PaletteColorOptions;
  }
  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
  }
}

// Extend the Theme interface to include custom properties
declare module "@mui/material/styles" {
  interface Theme {
    border: {
      color: string;
    };
  }
  // Allow configuration using `createTheme`
  interface ThemeOptions {
    border?: {
      color?: string;
    };
  }
}

const shadows: Shadows = [
  "none",
  "0 2px 8px rgba(0, 0, 0, 0.15)",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
];

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#fff", // primary color
    },
    secondary: {
      main: "#C4482B", // secondary color
    },
    success: {
      main: "#91AF55", // success color
    },
    warning: {
      main: "#DBA94D", // warning color
    },
    error: {
      main: "#E5350B", // error color
    },
    info: {
      main: "#5c987a", // link color
    },
    background: {
      default: "#000000", // default background color
      paper: "#232323", // paper background color
    },
    text: {
      primary: "#FFFFFF", // primary text color
      secondary: "#B0B0B0", // secondary text color
    },
    tertiary: {
      // add this block
      main: "#6A1B9A", // tertiary color
    },
  },
  typography: {
    allVariants: {
      color: "#FFFFFF", // default text color
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "#FFFFFF", // default text color for body
        },
        span: {
          color: "#FFFFFF", // default text color for spans
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#E5C281",
          },
          borderRadius: "4px",
          textTransform: "uppercase",
          padding: "10px 20px",
          fontWeight: "bold",
          "&.Mui-disabled": {
            backgroundColor: "#555555", // Custom background color for disabled state
            color: "#AAAAAA", // Custom text color for disabled state
          },
        },
        containedPrimary: {
          backgroundColor: "#DBA94D",
          "&:hover": {
            backgroundColor: "#B58432",
          },
          "&.Mui-disabled": {
            backgroundColor: "#B58432", // Disabled state for primary button
            color: "#DDDDDD", // Custom text color for disabled state
          },
        },
        containedSecondary: {
          backgroundColor: "#C4482B",
          "&:hover": {
            backgroundColor: "#A63620",
          },
          "&.Mui-disabled": {
            backgroundColor: "#A63620", // Disabled state for secondary button
            color: "#DDDDDD", // Custom text color for disabled state
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#5c987a", // link color
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            backgroundColor: "transparent", // No background
            fontSize: "14px", // Font size 12px
            padding: "6px", // Padding 6px
            color: "#FFFFFF",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#4C4C4C", // Border color
            },
            "&:hover fieldset": {
              borderColor: "#4C4C4C",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#4C4C4C", // Focus border color
            },
          },
        },
      },
    },
  },
  shadows,
  border: {
    color: "#d9d9d9", // border color
  },
};

const theme = createTheme(themeOptions);

export default theme;

import { RootState } from "@Store";
import { IBodyInviteUserIds, IInviteUserModal, IUserInvite } from "./types";

export const selectInvitedUsers = (state: RootState): IUserInvite[] =>
  state.invites.general.list;

export const selectInviteUsersIsLoading = (state: RootState): boolean =>
  state.invites.general.isLoading;

export const selectInviteUsersData = (state: RootState) =>
  state.invites.general;

export const selectShowDeleteInviteUserModal = (state: RootState): boolean =>
  !!state.invites.general.inviteUserModal.usersInvites.length &&
  state.invites.general.inviteUserModal.type === "delete";
export const selectShowNotifyInviteUserModal = (state: RootState): boolean =>
  !!state.invites.general.inviteUserModal.usersInvites.length &&
  state.invites.general.inviteUserModal.type === "notify";

export const selectInviteUserModal = (state: RootState): IInviteUserModal =>
  state.invites.general.inviteUserModal;

export const selectShowInviteUserModal = (state: RootState): boolean =>
  state.invites.general.inviteUserModal.type === "invite";

export const selectInviteUsersIds = (state: RootState): IBodyInviteUserIds => ({
  userIds: state.invites.general.inviteUserModal.usersInvites.map(
    (user) => user.id
  ),
});

import { createInterceptors } from "@API/middleware";
import { AxiosInstance, HttpStatusCode } from "axios";

export const createGetOrganizationsInterceptors = (
  organizationRequest: AxiosInstance
): void => {
  createInterceptors(organizationRequest, {
    responseSuccessMessage: "A list of information for all the organizations.",
    responseErrorMessage: "An error occurred while getting the organizations.",
    errorCallbackMessages: {
      [HttpStatusCode.Unauthorized]:
        "You need to have a valid keycloak token to access this endpoint.",
      [HttpStatusCode.ImATeapot]:
        "This device is unknown to the backend; you have to do a device specific 2FA.",
    },
  });
};

export const createPostCreateOrganizationRequestInterceptors = (
  createOrganizationRequest: AxiosInstance
): void => {
  createInterceptors(createOrganizationRequest, {
    responseSuccessMessage:
      "New organization information is stored; orgId is created; the stored information will be returned.",
    responseErrorMessage:
      "An error occurred while creating a new organization.",
    errorCallbackMessages: {
      [HttpStatusCode.BadRequest]:
        "The given meta-data for the organization is malformed; an error message will be provided.",
      [HttpStatusCode.Unauthorized]:
        "You need to have a valid keycloak token to access this endpoint.",
      [HttpStatusCode.Forbidden]:
        "You need to be a system admin to access this endpoint. .",
      [HttpStatusCode.ImATeapot]:
        "This device is unknown to the backend; you have to do a device specific 2FA.",
    },
  });
};

import {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  HttpStatusCode,
  InternalAxiosRequestConfig,
} from "axios";

export interface InterceptorOptions {
  /**
   * The callback to use when a request is made.
   * @type {Function}
   */
  requestCallback?: (
    value: InternalAxiosRequestConfig
  ) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>;
  /**
   * The callback to use when a response is received.
   * @type {Function}
   */
  responseCallback?: (
    value: AxiosResponse
  ) => AxiosResponse | Promise<AxiosResponse>;
  /**
   * The callback to use when an error occurs.
   * @type {Function}
   */
  errorCallback?: (error: AxiosError) => unknown;
}

/**
 * Creates interceptors for the specified Axios instance.
 * @param {AxiosInstance} axiosInstance The Axios instance to create interceptors for.
 * @param {InterceptorOptions} [options] The options to use when adding interceptors.
 */
const createInterceptorTemplate = (
  axiosInstance: AxiosInstance,
  options?: InterceptorOptions
): void => {
  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<unknown>) => {
      return options?.requestCallback
        ? options.requestCallback(config)
        : config;
    },
    (error) => {
      if (options?.errorCallback) {
        options.errorCallback(error);
      }
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse<unknown, unknown>) => {
      return options?.responseCallback
        ? options.responseCallback(response)
        : response;
    },
    (error) => {
      if (options?.errorCallback) {
        options.errorCallback(error);
      }
      return Promise.reject(error);
    }
  );
};

export interface InterceptorMessages {
  /**
   * Message to log when a successful response is received.
   * @type {string}
   */
  responseSuccessMessage: string;
  /**
   * Message to log when an error response is received.
   * @type {string}
   */
  responseErrorMessage: string;
  /**
   * Mapping of HTTP status codes to error messages for error responses.
   * @type {Record<number, string>}
   */
  errorCallbackMessages: Record<number, string>;
}

export const createInterceptors = (
  axiosInstance: AxiosInstance,
  interceptorMessages: InterceptorMessages
): void => {
  createInterceptorTemplate(axiosInstance, {
    responseCallback: (response: AxiosResponse) => {
      const status: number = response.status;

      return response;
    },
    errorCallback: (error: AxiosError): void => {
      const status: number | undefined = error.response?.status;

      if (status === undefined) return;

      const errorMessage =
        interceptorMessages.errorCallbackMessages[status] ??
        "An error occurred.";

      console.error(`${errorMessage} ${error.code}`);
    },
  });
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useAppDispatch } from "@hooks";
import { addOrganizationPolicy } from "@Store/Organizations/actions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
} from "@mui/material";
import { languageOptions } from "@Pages/CompanyLegal/consts";
import { SelectChangeEvent } from "@mui/material/Select";
import "./styles.scss";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }, { align: [] }],
    ["clean"],
  ],
};

function PrivacyTab({
  type,
  currentOrganization,
  policyData,
}: {
  type: string;
  currentOrganization: any;
  policyData: any;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [language, setLanguage] = useState("english");
  const [contentEN, setContentEN] = useState(policyData?.contentDE);
  const [contentDE, setContentDE] = useState(policyData?.contentEN);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newLanguage, setNewLanguage] = useState(language);

  const updatePolicy = () => {
    const formData = {
      org_id: currentOrganization?.orgId,
      language: language,
      type: type,
      contentEN: contentEN,
      contentDE: contentDE,
    };
    dispatch(addOrganizationPolicy(formData));
  };

  useEffect(() => {
    setContentEN(policyData?.contentEN);
    setContentDE(policyData?.contentDE);
  }, [policyData]);

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    const hasChanges =
      (language === "english" && contentEN !== policyData?.contentEN) ||
      (language === "german" && contentDE !== policyData?.contentDE);
    if (hasChanges) {
      setNewLanguage(value);
      setIsModalVisible(true);
    } else {
      setLanguage(value);
    }
  };

  const handleConfirmChangeLanguage = () => {
    setLanguage(newLanguage);
    if (newLanguage === "english") {
      setContentDE(policyData?.contentDE);
    } else {
      setContentEN(policyData?.contentEN);
    }
    setIsModalVisible(false);
  };

  const handleCancelChangeLanguage = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="page-container">
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h2>
          {" "}
          {type === "PRIVACY"
            ? t("sidebar.organization.privacy")
            : t("sidebar.organization.terms")}
        </h2>

        <div style={{ marginTop: "15px" }}>
          <Button variant="contained" color="primary" onClick={updatePolicy}>
            {t("buttons.save")}
          </Button>
        </div>
      </div>

      <Select
        value={language}
        onChange={handleLanguageChange}
        displayEmpty
        variant="outlined"
        style={{
          width: "40%",
          marginBottom: "25px",
          backgroundColor: "#2b2b2b",
        }}
      >
        {languageOptions.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>

      <div className="editor-container" key={language}>
        {language === "english" ? (
          <ReactQuill
            theme="snow"
            value={contentEN}
            onChange={setContentEN}
            modules={modules}
          />
        ) : (
          <ReactQuill
            theme="snow"
            value={contentDE}
            onChange={setContentDE}
            modules={modules}
          />
        )}
      </div>

      <Dialog open={isModalVisible} onClose={handleCancelChangeLanguage}>
        <DialogTitle>
          {t("sidebar.organization.changeLanguage.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("sidebar.organization.changeLanguage.content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelChangeLanguage} color="primary">
            {t("cancel")}
          </Button>
          <Button
            onClick={handleConfirmChangeLanguage}
            color="primary"
            autoFocus
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PrivacyTab;

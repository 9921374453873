import React from "react";
import { Button } from "@mui/material";
import ArrowLeftIconSvg from "@src/Constants/svg/arrow-left-icon-svg";
import "./styles.scss";

const SingleOrganizationHeader: React.FC = () => (
  <div className="single-organization-header">
    <Button variant="contained" className="back-button">
      <ArrowLeftIconSvg />
      {"Back"}
    </Button>
    <Button variant="contained" className="edit-button">
      {"Edit Organization"}
    </Button>
  </div>
);

export default SingleOrganizationHeader;

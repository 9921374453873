import {
    getAxiosAdminOrganizationRequest,
    getAxiosOrganizationRequest,
    postAxiosCreateOrganizationRequest,
} from "@API";
import {
  createGetOrganizationsInterceptors,
  createPostCreateOrganizationRequestInterceptors,
} from "@API/Organizations/middlewares";
import {
  IAxiosOrganization,
  IGetAxiosOrganizations,
  IPostAxiosOrganization,
  NewOrganizationPayload,
} from "@API/Organizations/types";
import { IOrganization } from "@Store/Organizations/types";
import { AxiosInstance, AxiosResponse } from "axios";

export const getAxiosOrganizations =
  async (): Promise<IGetAxiosOrganizations> => {
    const organizationsRequest: AxiosInstance = getAxiosOrganizationRequest();

    createGetOrganizationsInterceptors(organizationsRequest);

    const response: AxiosResponse = await organizationsRequest.get("");

    const organizationsData: IOrganization[] = response.data;

    return { data: organizationsData };
  };

export const getAxiosOrganizationById = async (id: string): Promise<IAxiosOrganization> => {
    const organizationsRequest: AxiosInstance = getAxiosOrganizationRequest();

        createGetOrganizationsInterceptors(organizationsRequest);

        const response: AxiosResponse = await organizationsRequest.get(id);

        const organizationData: IOrganization = response.data;

        return { data: organizationData };
    };

export const putAxiosOrganization = async (
  payload: IOrganization
): Promise<IAxiosOrganization> => {
  // const response = await getApiRequest().put(`/organizations/${payload.id}/`, formatBody(payload));

  return { data: payload };
};

export const postAxiosCreateOrganization = async (
  payload: NewOrganizationPayload
): Promise<IPostAxiosOrganization> => {
  const createOrganizationRequest: AxiosInstance =
    postAxiosCreateOrganizationRequest();

  createPostCreateOrganizationRequestInterceptors(createOrganizationRequest);

  const response: AxiosResponse = await createOrganizationRequest.post(
    "",
    payload
  );

  const newOrganizationData: IOrganization = response.data;

  return { data: newOrganizationData };
};

export const deleteAxiosOrganization = async (payload: number): Promise<{}> => {
  // await getApiRequest().delete(`/organizations/${payload}`));

  return {};
};

export const postAxiosUploadFile = async (
    org_id: string,
    fileType: string,
    file: FormData
): Promise<void> => {
    const fileUploadRequest: AxiosInstance = getAxiosAdminOrganizationRequest();

    await fileUploadRequest.post(
        `/${org_id}/files/${fileType}`,
        file,
    );
};

export const addOrganizationPolicyRequest = async (data: any): Promise<void> => {
    const endpoint = `/${data.org_id}/policies`;
    const payload = {
        type: data.type,
        contentEN: data.contentEN,
        contentDE: data.contentDE,
        validUntil: data.validUntil,
    };

    const fileUploadRequest: AxiosInstance = getAxiosAdminOrganizationRequest();
    await fileUploadRequest.post(endpoint, payload);
};

export const putAxiosActivateUser = async (
    org_id: string,
    user_id: string,
    isActive: boolean
): Promise<void> => {
    const axiosAdminOrgRequest: AxiosInstance = getAxiosAdminOrganizationRequest();

    await axiosAdminOrgRequest.put(`/${org_id}/users/${user_id}/${!isActive ? 'activate' : 'deactivate'}`);
};


import React from "react";

interface ProgressBarProps {
  value: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value }) => {
  const normalizedValue = Math.max(1, Math.min(100, value));

  const barStyle: React.CSSProperties = {
    height: "8px",
    width: "200px",
    backgroundColor: "#4C4C4C",
    borderRadius: "10px",
    overflow: "hidden",
  };

  const fillStyle: React.CSSProperties = {
    height: "100%",
    width: `${normalizedValue}%`,
    backgroundColor: "#91AF55",
    borderRadius: "10px 0 0 10px",
  };

  return (
    <div style={barStyle}>
      <div style={fillStyle}></div>
    </div>
  );
};

export default ProgressBar;

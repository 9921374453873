import { BodyData } from "@API/App/types";

/**
 * Formats an object into a URL-encoded string using the `URLSearchParams` API.
 * @param {BodyData} data The data object to format.
 * @returns {URLSearchParams} A new `URLSearchParams` instance containing the formatted data.
 * @example
 * const formattedBody: URLSearchParams = formatBody({
 *  username: "user",
 *  password: "password",
 * });
 *
 * // The formattedBody variable now contains the following:
 * "username=user&password=password"
 */
export const formatBody = (data: BodyData): URLSearchParams => {
  const urlencoded: URLSearchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(data)) {
    urlencoded.append(key, value);
  }

  return urlencoded;
};

/**
 * Gets the user agent information.
 * @returns {string} The user agent information.
 * @example
 * const userAgent: string = getUserAgentInfo();
 *
 * // The userAgent variable now contains the following:
 * "Chrome 114"
 */
export const getUserAgentInfo = (): string => {
  let userAgent: string = navigator.userAgent;
  let temp;
  let match: RegExpMatchArray =
    userAgent.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) ?? ([] as unknown as RegExpMatchArray);

  if (/trident/i.test(match[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return "IE " + (temp[1] || "");
  }

  if (match[1] === "Chrome") {
    temp = RegExp(/\b(OPR|Edge)\/(\d+)/).exec(userAgent);

    if (temp != null) {
      return temp.slice(1).join(" ").replace("OPR", "Opera");
    }
  }

  match = match[2]
    ? [match[1], match[2]]
    : ["Netscape", navigator.appVersion, "-?"];

  if ((temp = RegExp(/version\/(\d+)/i).exec(userAgent)) != null)
    match.splice(1, 1, temp[1]);

  return match.join(" ");
};

/**
 * Parses a date string into a `Date` object.
 * @param date The date string to parse.
 * @returns {Date} The parsed date.
 */
export const parseDateStringToDate = (date: string): Date => new Date(date);

import "./index.scss";
import { RootState } from "@Store";
import { setLanguage } from "@Store/App/actions";
import { selectLanguage } from "@Store/App/selectors";
import { lang } from "@Store/App/types";
import { useAppDispatch, useAppSelector } from "@hooks";
import { MenuItem, Select, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

interface ILanguageSelect {
  styles?: React.CSSProperties;
  svgStyles?: React.CSSProperties;
}

const useStyles = makeStyles({
  icon: (props: ILanguageSelect) => ({
    color: props?.svgStyles?.color || "#FFFFFF",
  }),
  select: (props: ILanguageSelect) => ({
    width: "75px",
    height: "32px",
    backgroundColor: props?.styles?.backgroundColor || "#363636",
    color: props?.styles?.color || "#FFFFFF",
    "& .MuiSelect-select": {
      paddingRight: "28px",
    },
    "& svg": {
      color: props?.svgStyles?.color || "#FFFFFF",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    outline: "none",
    borderRadius: "2px !important",
  }),
  menuPaper: {
    borderRadius: "2px !important",
    backgroundColor: "#363636",
  },
});

const LanguageSelectAction: React.FC<ILanguageSelect> = (props) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const classes = useStyles(props);

  const { selectedLanguage } = useAppSelector((state: RootState) => ({
    selectedLanguage: selectLanguage(state),
  }));

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    moment.locale(selectedLanguage);
    i18n
      .changeLanguage(selectedLanguage)
      .catch((reason) => console.error(reason));
  }, [i18n, selectedLanguage]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSelect = (value: lang) => {
    dispatch(setLanguage(value));
  };

  return (
    <Tooltip title="" placement="bottom">
      <Select
        value={selectedLanguage}
        onChange={(e) => onSelect(e.target.value as lang)}
        className={classes.select}
        displayEmpty
        MenuProps={{
          classes: { paper: classes.menuPaper },
        }}
      >
        <MenuItem value="de">DE</MenuItem>
        <MenuItem value="en">EN</MenuItem>
      </Select>
    </Tooltip>
  );
};

export default LanguageSelectAction;

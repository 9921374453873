import {
  combineReducers,
  createReducer,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  appUserDataFail,
  appUserDataRequest,
  appUserDataSuccess,
  uploadCsvFileFail,
  uploadCsvFileRequest,
  uploadCsvFileSuccess,
} from "./actions";
import { IAppUser } from "./types";

interface IAppUserInitialState {
  data?: IAppUser;
  isLoading: boolean;
  success: boolean;
  fail: boolean;
}

const APP_USER_INITIAL_STATE: IAppUserInitialState = {
  data: undefined,
  isLoading: false,
  success: false,
  fail: false,
};

const appUserDataReducer = createReducer(APP_USER_INITIAL_STATE, {
  [appUserDataRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [appUserDataSuccess.type]: (state, { payload }: PayloadAction<IAppUser>) => {
    // Create a new timestamp
    const timestamp = new Date().getTime();

    // Check and update the payload data, particularly the memberships
    const updatedMemberships = payload.memberships.map((membership) => ({
      ...membership,
      // Append the timestamp to the orgLogoUrl if it exists
      orgLogoUrl: membership.orgLogoUrl
        ? `${membership.orgLogoUrl}?${timestamp}`
        : membership.orgLogoUrl,
      // Append the timestamp to the orgInfoUrl if it exists
      orgInfoUrl: membership.orgInfoUrl
        ? `${membership.orgInfoUrl}?${timestamp}`
        : membership.orgInfoUrl,
    }));

    // Construct the new payload with updated memberships
    const updatedPayload = {
      ...payload,
      memberships: updatedMemberships,
    };

    // Return the updated state
    return {
      ...state,
      data: updatedPayload,
      isLoading: false,
      success: true,
      fail: false,
    };
  },

  [appUserDataFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),

  [uploadCsvFileRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    uploadSuccess: false,
    uploadError: null,
  }),
  [uploadCsvFileSuccess.type]: (state, action) => ({
    ...state,
    isLoading: false,
    uploadSuccess: true,
    uploadError: null,
    uploadedData: action.payload,
  }),
  [uploadCsvFileFail.type]: (state, action) => ({
    ...state,
    isLoading: false,
    uploadSuccess: false,
    uploadError: action.payload,
  }),
});

export interface IGeneralState {
  readonly general: IAppUserInitialState;
}

export default combineReducers<IGeneralState>({
  general: appUserDataReducer,
});

import React from "react";
import { Card, CardContent, Button } from "@mui/material";
import "./styles.scss";

const AuthorizationCard: React.FC = () => (
  <Card className="single-organization-content-card">
    <div className="title">Authorization</div>
    <CardContent className="card-content">
      <div className="authorization-details">
        <span className="authorization-info">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy ro eos et accusam et justo duo dolores et ea rebum. Stet clita
          kasd gubergren, no sea takimata.
        </span>
        <Button variant="contained" className="edit-button" color="primary">
          {"Access for edit"}
        </Button>
      </div>
    </CardContent>
  </Card>
);

export default AuthorizationCard;

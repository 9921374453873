import React from "react";
import { JSX } from "react/jsx-runtime";

const ArrowLeftIconSvg = (
  props: JSX.IntrinsicAttributes &
    React.SVGProps<SVGSVGElement> & { fill?: string }
) => {
  const { fill = "white", ...rest } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5152 2.73483C11.6617 2.88128 11.6617 3.11872 11.5152 3.26516L5.78039 9L11.5152 14.7348C11.6617 14.8813 11.6617 15.1187 11.5152 15.2652C11.3688 15.4116 11.1313 15.4116 10.9849 15.2652L4.71973 9L10.9849 2.73483C11.1313 2.58839 11.3688 2.58839 11.5152 2.73483Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowLeftIconSvg;

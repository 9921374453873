import { createInterceptors } from "@API/middleware";
import { AxiosInstance, HttpStatusCode } from "axios";

export const createGetOrganizationUsersInterceptors = (
  organizationUsersRequest: AxiosInstance
): void => {
  createInterceptors(organizationUsersRequest, {
    responseSuccessMessage: "A list with all users' data is returned.",
    responseErrorMessage:
      "An error occurred while getting the organization user's data.",
    errorCallbackMessages: {
      [HttpStatusCode.Unauthorized]:
        "You need to have a valid keycloak token to access this endpoint.",
      [HttpStatusCode.Forbidden]:
        "You are not allowed to fetch this information.",
      [HttpStatusCode.ImATeapot]:
        "This device is unknown to the backend; You have to do device-specific 2FA.",
    },
  });
};

export const createPatchOrganizationUserInterceptors = (
  changeOrganizationUsersRequest: AxiosInstance
): void => {
  createInterceptors(changeOrganizationUsersRequest, {
    responseSuccessMessage:
      "The user attributes were changed successfully; the updated user's data is returned.",
    responseErrorMessage: "An error occurred while updating organization user.",
    errorCallbackMessages: {
      [HttpStatusCode.BadRequest]:
        "The given user data does not fit the requirements.",
      [HttpStatusCode.Unauthorized]:
        "You need to have a valid keycloak token to access this endpoint.",
      [HttpStatusCode.Forbidden]:
        "You are not allowed to change the selected user in this org.",
      [HttpStatusCode.NotFound]: "Unknown user identifier given.",
      [HttpStatusCode.ImATeapot]:
        "This device is unknown to the backend; you have to do device-specific 2FA.",
    },
  });
};

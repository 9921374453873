import { ICountry, IAddressType, IPhoneNumberType, IRole, ITeamRole } from "@Store/MasterData/types";

export const countries: ICountry[] = [
  {
    id: 1,
    isoCode: 'DE',
    name: 'germany',
    phoneAreaCode: '+49',
  },
  {
    id: 2,
    isoCode: 'FR',
    name: 'france',
    phoneAreaCode: '+33',
  },
  {
    id: 3,
    isoCode: 'NL',
    name: 'netherlands',
    phoneAreaCode: '+31',
  },
  {
    id: 4,
    isoCode: 'PL',
    name: 'poland',
    phoneAreaCode: '+48',
  },
  {
    id: 5,
    isoCode: 'IT',
    name: 'italy',
    phoneAreaCode: '+39',
  },
  {
    id: 6,
    isoCode: 'GB',
    name: 'unitedKingdom',
    phoneAreaCode: '+44',
  },
];

export const addressTypes: IAddressType[] = [
  {
    id: 1,
    name: 'home',
  },
  {
    id: 2,
    name: 'business',
  },
  {
    id: 3,
    name: 'billing',
  },
  {
    id: 4,
    name: 'shipping',
  },
];

export const phoneNumberTypes: IPhoneNumberType[] = [
  {
    id: 1,
    name: 'mobile',
  },
  {
    id: 2,
    name: 'mobile_business',
  },
  {
    id: 3,
    name: 'home',
  },
  {
    id: 4,
    name: 'main',
  },
  {
    id: 5,
    name: 'fax',
  },
  {
    id: 6,
    name: 'fax_business',
  },
];

export const roles: IRole[] = [
  {
    id: 1,
    name: 'none',
  },
  {
    id: 2,
    name: 'user',
  },
  {
    id: 3,
    name: 'admin',
  },
  {
    id: 4,
    name: 'super_admin',
  },
];

export const teamRoles: ITeamRole[] = [
  {
    id: 1,
    name: 'user',
  },
  {
    id: 2,
    name: 'admin',
  },
];
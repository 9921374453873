import { createInterceptors } from "@API/middleware";
import { AxiosInstance, HttpStatusCode } from "axios";

export const createGetMatrixServersInterceptors = (
  matrixServersRequest: AxiosInstance
): void => {
  createInterceptors(matrixServersRequest, {
    responseSuccessMessage:
      "A list with information about known matrix servers is returned.",
    responseErrorMessage: "An error occurred while getting the matrix servers.",
    errorCallbackMessages: {
      [HttpStatusCode.Unauthorized]:
        "You need to have a valid keycloak token to access this endpoint.",
      [HttpStatusCode.Forbidden]:
        "You need to be a system admin to access this endpoint.",
      [HttpStatusCode.ImATeapot]:
        "This device is unknown to the backend; you have to do a device specific 2FA.",
    },
  });
};

import { mockedUsers } from "@API/_usersMocks";
import { IInviteUser } from "@Store/Invites/types";
import { IMasterData } from "@Store/MasterData/types";
import { IOrganizationUser } from "@Store/Organizations/types";
import { isOrganizationUserStatusActive } from "@Store/Organizations/utilities";
import {
  addressTypes,
  countries,
  phoneNumberTypes,
  roles,
  teamRoles,
} from "./_generalMock";
import { inviteUsers } from "./_inviteUsersMocks";

export const AppAdminUserResData = {
  id: 2,
  email: "frauke@blackpin.de",
  emailShow: true,
  firstname: "Frauke",
  firstnameShow: true,
  lastname: "Schiller",
  lastnameShow: true,
  department: undefined,
  departmentJob: undefined,
  // superAdmin: true,
  superAdmin: false,
  // isSystem: true,
  addresses: [],
  phoneNumbers: [
    {
      id: 1,
      number: "175 945 57 54",
      country: {
        id: 1,
        isoCode: "DE",
        name: "germany",
        phoneAreaCode: "+49",
      },
      phoneNumberType: {
        id: 5,
        name: "main",
      },
    },
  ],
  role: {
    id: 4,
    name: "super_admin",
  },
};

export const AppSuperAdminUserResData = {
  id: 2,
  email: "frauke@blackpin.de",
  emailShow: true,
  firstname: "Frauke",
  firstnameShow: true,
  lastname: "Schiller",
  lastnameShow: true,
  department: undefined,
  departmentJob: undefined,
  superAdmin: true,
  // superAdmin: false,
  // isSystem: true,
  addresses: [],
  phoneNumbers: [
    {
      id: 1,
      number: "175 945 57 54",
      country: {
        id: 1,
        isoCode: "DE",
        name: "germany",
        phoneAreaCode: "+49",
      },
      phoneNumberType: {
        id: 5,
        name: "main",
      },
    },
  ],
  role: {
    id: 4,
    name: "super_admin",
  },
};

export const MasterDataRes: IMasterData = {
  countries,
  addressTypes,
  phoneNumberTypes,
  roles,
  teamRoles,
};

// export const teamMemberRes = usersListResData.map(mapUsersForTeam)[1];

import React, { SetStateAction, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import FileUpload from "@Components/FileUpload/file-upload";
import {
  getOrganizationByIdRequest,
  uploadImageOrganizationRequest,
} from "@Store/Organizations/actions";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@hooks";
import { useHistory } from "react-router-dom";
import { RootState } from "@Store";
import {
  selectCurrentOrganization,
  selectIsAdminOfCurrentOrganization,
} from "@Store/Organizations/selectors";
import { selectAppUserData } from "@Store/AppUser/selectors";
import "./styles.scss";

const CorporatePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [logoFormData, setLogoFormData] = useState(() => new FormData());
  const [profileFormData, setProfileFormData] = useState(() => new FormData());
  const [isLogoSaveDisabled, setIsLogoSaveDisabled] = useState(true);
  const [isProfileSaveDisabled, setIsProfileSaveDisabled] = useState(true);

  const { currentOrganization, userData, isAdminOfCurrentOrganization } =
    useAppSelector((state: RootState) => ({
      currentOrganization: selectCurrentOrganization(state),
      userData: selectAppUserData(state),
      isAdminOfCurrentOrganization: selectIsAdminOfCurrentOrganization(state),
    }));

  const updateLogoFormData = (newFormData: SetStateAction<FormData>) => {
    setLogoFormData(newFormData);
    setIsLogoSaveDisabled(false);
  };

  const updateProfileFormData = (newFormData: SetStateAction<FormData>) => {
    setProfileFormData(newFormData);
    setIsProfileSaveDisabled(false);
  };

  const handleUploadLogo = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      uploadImageOrganizationRequest({
        org_id: currentOrganization?.orgId,
        file_type: "LOGO",
        file: logoFormData,
      })
    );
  };

  const handleUploadProfile = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      uploadImageOrganizationRequest({
        org_id: currentOrganization?.orgId,
        file_type: "PROFILE",
        file: profileFormData,
      })
    );
  };

  return (
    <Box className="root">
      <div style={{ width: "95%" }}>
        <h2>{t("sidebar.organization.corporate")}</h2>
        <div className="CorporateContainer">
          <div className="CorporateContainerLeftSide">
            <div className="CorporateContainerLeftSideUpload">
              <h2>{t("general.logo")}</h2>
              <FileUpload
                formData={logoFormData}
                accept={"image/*"}
                id={"logo-file"}
                name={"file"}
                labelText={t("buttons.upload")}
                updateParentFormData={updateLogoFormData}
                initialFileSrc={currentOrganization.orgLogoUrl || ""}
                preview={true}
                onFileStateChange={(state) => {
                  setIsLogoSaveDisabled(!state);
                }}
                fileInfo={"Min 200x200px, max 5MB"}
              />
            </div>
            <div className="CorporateContainerLeftSideActions">
              <Button
                variant="contained"
                color="primary"
                disabled={isLogoSaveDisabled}
                onClick={handleUploadLogo}
                style={{ marginBottom: "5px", marginRight: "25px" }}
              >
                {t("buttons.save")}
              </Button>
            </div>
          </div>
          <div className="CorporateContainerRightSide">
            <div className="CorporateContainerRightSideUpload">
              <h2>{t("general.avatar")}</h2>
              <FileUpload
                formData={profileFormData}
                accept={"image/*"}
                id={"profile-file"}
                name={"file"}
                labelText={t("buttons.upload")}
                updateParentFormData={updateProfileFormData}
                initialFileSrc={currentOrganization.orgInfoUrl || ""}
                preview={true}
                onFileStateChange={(state) => {
                  setIsProfileSaveDisabled(!state);
                }}
                fileInfo={"Min 200x200px, max 5MB"}
              />
            </div>
            <div className="CorporateContainerRightSideActions">
              <Button
                variant="contained"
                color="primary"
                disabled={isProfileSaveDisabled}
                onClick={handleUploadProfile}
                style={{ marginBottom: "5px", marginRight: "25px" }}
              >
                <span>{t("buttons.save")}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default CorporatePage;

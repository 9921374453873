import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import { useAppSelector } from "@hooks";
import { RootState } from "@Store";
import {
  selectCurrentOrganization,
  selectIsAdminOfCurrentOrganization,
} from "@Store/Organizations/selectors";
import { selectAppUserData } from "@Store/AppUser/selectors";

interface FileUploadProps {
  id: string;
  name: string;
  accept: string;
  formData: FormData;
  updateParentFormData: (formData: FormData) => void;
  labelText: string;
  initialFileSrc?: string | null;
  onFileStateChange?: (b: boolean) => void;
  preview?: boolean;
  fileInfo?: string;
  className?: string;
  buttonText?: string;
}

const FileUploadContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const FileUploadInfo = styled(Typography)({
  marginTop: "8px",
  display: "flex",
  justifyContent: "end",
  fontSize: "12px",
  fontWeight: "600",
});

const HiddenInput = styled("input")({
  display: "none",
});

const ImagePlaceholderContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "500px",
  height: "200px",
  border: "1px dashed #ccc",
  borderRadius: "8px",
  marginBottom: "8px",
});

const UploadBox = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const UploadBoxNoPreview = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px dashed #ccc",
  borderRadius: "8px",
  padding: "16px",
  marginBottom: "8px",
  width: "350px",
  height: "50px",
  justifyContent: "center",
});

const UploadedImage = styled("img")({
  width: "200px",
  height: "200px",
  objectFit: "cover",
  borderRadius: "8px",
  minHeight: "70px",
  minWidth: "70px",
});

const InputLabel = styled("label")({
  marginTop: "8px",
  width: "100%",
});

const RemoveButton = styled(IconButton)({
  position: "absolute",
  top: "8px",
  right: "8px",
  background: "rgba(255, 255, 255, 0.7)",
  "&:hover": {
    background: "rgba(255, 255, 255, 1)",
  },
});

const FileUpload: React.FC<FileUploadProps> = ({
  id,
  name,
  accept,
  formData,
  updateParentFormData,
  labelText,
  initialFileSrc,
  onFileStateChange,
  preview = false,
  fileInfo = "Min 200x200px, max 5MB",
  className,
  buttonText,
}) => {
  const [fileSrc, setFileSrc] = useState(initialFileSrc);
  const [fileName, setFileName] = useState<string | null>(null);
  const [userUploadedFileSrc, setUserUploadedFileSrc] = useState<string | null>(
    null
  );

  const { currentOrganization, userData, isAdminOfCurrentOrganization } =
    useAppSelector((state: RootState) => ({
      currentOrganization: selectCurrentOrganization(state),
      userData: selectAppUserData(state),
      isAdminOfCurrentOrganization: selectIsAdminOfCurrentOrganization(state),
    }));

  useEffect(() => {
    if (!userUploadedFileSrc) {
      setFileSrc(initialFileSrc);
    }
  }, [initialFileSrc, userUploadedFileSrc]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      if (preview) {
        setFileSrc(fileURL);
        setUserUploadedFileSrc(fileURL);
      } else {
        setFileName(file.name);
      }
      formData.delete(name);
      formData.append(name, file);
      updateParentFormData(formData);
      onFileStateChange?.(true);
    }
  };

  const removeFile = () => {
    setFileSrc(null);
    setUserUploadedFileSrc(null);
    setFileName(null);
    formData.delete(name);
    updateParentFormData(formData);
    onFileStateChange?.(false);
  };

  useEffect(() => {
    removeFile();
    setFileSrc(initialFileSrc);
  }, [currentOrganization]);

  return (
    <FileUploadContainer className={className}>
      {preview ? (
        <UploadBox>
          {fileSrc ? (
            <div>
              <UploadedImage
                src={fileSrc}
                alt="Uploaded preview"
                onError={removeFile}
              />
              <RemoveButton onClick={removeFile}>
                <DeleteIcon />
              </RemoveButton>
            </div>
          ) : (
            <ImagePlaceholderContainer>
              <img
                width={20}
                height={20}
                src={`${process.env.PUBLIC_URL}/Assets/imagePlaceholder.svg`}
                alt="placeholder"
                onError={() => {
                  console.error("Error loading image");
                }}
              />
            </ImagePlaceholderContainer>
          )}
        </UploadBox>
      ) : (
        <UploadBoxNoPreview>
          {fileName ? (
            <div>
              <Typography>{fileName}</Typography>
              <RemoveButton onClick={removeFile}>
                <DeleteIcon />
              </RemoveButton>
            </div>
          ) : (
            <div>
              <Typography className="upload-file-placeholder">
                UPLOAD FILE ...
              </Typography>
            </div>
          )}
        </UploadBoxNoPreview>
      )}
      <InputLabel htmlFor={id}>
        <HiddenInput
          id={id}
          type="file"
          name={name}
          accept={accept}
          onChange={handleFileChange}
        />
        <Button
          variant="contained"
          component="span"
          style={{ height: "30px", width: "100%" }}
        >
          {buttonText || labelText}
        </Button>
      </InputLabel>
      <FileUploadInfo>{fileInfo}</FileUploadInfo>
    </FileUploadContainer>
  );
};

export default FileUpload;

import { getAxiosMatrixServersRequest } from "@API";
import { AxiosInstance, AxiosResponse } from "axios";

import { createGetMatrixServersInterceptors } from "@API/Matrix/middlewares";
import { IGetAxiosMatrixServers, IMatrixServer } from "@API/Matrix/types";

export const getAxiosMatrixServers =
  async (): Promise<IGetAxiosMatrixServers> => {
    const matrixServersRequest: AxiosInstance = getAxiosMatrixServersRequest();

    createGetMatrixServersInterceptors(matrixServersRequest);

    const response: AxiosResponse = await matrixServersRequest.get("/config");

    const matrixServersData: IMatrixServer[] = response.data;

    return { data: matrixServersData };
  };

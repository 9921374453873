import React, { useEffect, useState, useCallback } from "react";
import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CustomTable, { Column } from "@Components/Table";
import { selectCurrentOrganization } from "@Store/Organizations/selectors";
import { IUserInvite } from "@Store/Invites/types";
import { useAppSelector } from "@hooks";
import { selectInvitedUsers } from "@Store/Invites/selectors";
import { inviteUsersListRequest } from "@Store/Invites/actions";
import { RootState } from "@Store";
import { useDispatch } from "react-redux";
import i18n from "i18next";
import BlackPinTag from "@Components/BlackPinTag";
import ModalInviteUser from "@Components/ModalInviteUser";
import SearchIcon from "@mui/icons-material/Search";
import { StyledOutlinedInput } from "@Pages/AdminPages/UsersManagement";
import _ from "lodash"; // Import lodash for debouncing

const InvitationsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const invitedUsersList: IUserInvite[] = useAppSelector(selectInvitedUsers);
  const { currentOrganization } = useAppSelector((state: RootState) => ({
    currentOrganization: selectCurrentOrganization(state),
  }));
  const [data, setData] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [inviteUserModalVisible, setInviteUserModalVisible] = useState(false);
  const showModalInviteUser = () => {
    setInviteUserModalVisible(true);
  };
  const hideModalInviteUser = () => {
    setInviteUserModalVisible(false);
  };

  const columns: Column[] = [
    {
      field: "firstname",
      headerName: t("organizationUsers.columns.firstname"),
    },
    { field: "lastname", headerName: t("organizationUsers.columns.lastname") },
    { field: "username", headerName: t("organizationUsers.columns.username") },
    {
      field: "invitedBy",
      headerName: t("organizationUsers.columns.invitedBy"),
    },
    { field: "revoked", headerName: t("organizationUsers.columns.revoked") },
    { field: "status", headerName: t("organizationUsers.columns.status") },
    { field: "optValid", headerName: t("organizationUsers.columns.optValid") },
  ];

  useEffect(() => {
    setData([]);
    currentOrganization &&
      dispatch(inviteUsersListRequest(currentOrganization.orgId));
  }, [currentOrganization]);

  const handleSearch = useCallback(
    _.debounce((query) => {
      setSearchQuery(query);
    }, 300),
    []
  );

  const getStatus = (user: IUserInvite) => {
    if (user.acceptationTs === null) {
      return (
        <BlackPinTag
          color="Green"
          text={<>{i18n.t("inviteUser.accepted")} </>}
        />
      );
    }

    // Case where user has not accepted the invite

    const currentTime: number = new Date().getTime();
    const timeRemaining: number = +user.validUntil - currentTime;

    // Case where invite has expired

    if (timeRemaining <= 0) {
      return <BlackPinTag color="Red" text="Expired" />;
    }

    return (
      <BlackPinTag
        color="Orange"
        text={<>{i18n.t("inviteUser.waitingForAcceptance")} </>}
      />
    );
  };

  useEffect(() => {
    setData(
      invitedUsersList
        .filter((user) =>
          `${user.invitedOrgUserGivenName} ${user.invitedOrgUserSurname} ${user.invitedOrgUserUsername} ${user.invitedByUsername}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
        .map((invitedUser) => ({
          id: invitedUser.invitationId,
          firstname: invitedUser.invitedOrgUserGivenName,
          lastname: invitedUser.invitedOrgUserSurname,
          username: invitedUser.invitedOrgUserUsername,
          createdDate: "dsa",
          invitedBy: invitedUser.invitedByUsername,
          revoked: !invitedUser.revoked ? (
            <BlackPinTag color="Green" text={i18n.t("inviteUser.notRevoked")} />
          ) : (
            <BlackPinTag color="Red" text={i18n.t("inviteUser.revoked")} />
          ),
          status: getStatus(invitedUser),
          optValid: invitedUser.getOtpValidUntil?.toLocaleString() || "N/A",
        }))
    );
  }, [invitedUsersList, searchQuery]);

  return (
    <Box className="root">
      <ModalInviteUser
        visible={inviteUserModalVisible}
        onClose={hideModalInviteUser}
      />

      <div className="clients-organisation-header">
        <h2>{t("inviteUser.invitations")}</h2>
        <div>
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: "4px",
            }}
            onClick={showModalInviteUser}
          >
            <MailOutlineIcon />{" "}
            <span
              style={{ marginLeft: "10px", color: "#000", fontWeight: "500" }}
            >
              {t("inviteUser.buttons.inviteUser")}
            </span>
          </Button>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          marginBottom: "20px",
        }}
      >
        <StyledOutlinedInput
          id="outlined-adornment-search"
          type={"text"}
          placeholder="Search..."
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="search icon" edge="start">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => handleSearch(e.target.value)}
          label="Search"
        />
      </div>
      <div>
        <div style={{ height: "100%" }}>
          {invitedUsersList.length > 0 ? (
            <CustomTable
              key={JSON.stringify(invitedUsersList)}
              columns={columns}
              data={data}
              selectable={false}
            />
          ) : (
            <span>{t("inviteUser.messages.noInvites")}</span>
          )}
        </div>
      </div>
    </Box>
  );
};

export default InvitationsPage;

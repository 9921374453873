import { RootState } from "@Store";
import { createSelector } from "@reduxjs/toolkit";
import { IAppUser } from "./types";
import { IOrganization, OrganizationId } from "@Store/Organizations/types";

export const selectIsAppUserLoading = (state: RootState): boolean =>
  state.appUser.general.isLoading;

export const selectAppUserData = (state: RootState): IAppUser => {
  if (state.appUser.general.data === undefined) {
    return {
      sub: "",
      userId: "",
      givenName: "",
      surName: "",
      displayName: "",
      emailAddress: "",
      presence: "OFFLINE",
      presenceDescriptionMap: {
        AVAILABLE: "",
        AWAY: "",
        BUSY: "",
        OFFLINE: "",
      },
      isSystemAdmin: false,
      matrixId: "",
      matrixHomeServer: "",
      enabledKeyPassphrase: false,
      memberships: [],
    } as IAppUser;
  }
  return state.appUser.general.data;
};

export const selectIsSystemAdmin = createSelector(
  selectAppUserData,
  (appUser: IAppUser) => appUser.isSystemAdmin
);

export const selectFilteredOrganizations = createSelector(
  (state: RootState): IAppUser => selectAppUserData(state),
  (appUser: IAppUser): any[] => {
    // Filter memberships where orgUser.role is not SIMPLE_USER and map to organization details
    return (
      appUser.memberships
        // .filter(
        //   (membership) =>
        //     membership.orgUser && membership.orgUser.role !== "ORG_ADMIN"
        // )
        .map((membership) => ({
          orgId: membership.orgId,
          orgName: membership.orgName,
          device2FaRequired: membership.device2FaRequired,
          orgInfoUrl: membership.orgInfoUrl,
          orgLogoUrl: membership.orgLogoUrl,
          matrixHomeServer: membership.matrixHomeServer,
          orgUser: membership.orgUser,
        }))
    );
  }
);

export const selectSystemEditOrganization =
  (searchOrgId: OrganizationId | undefined) =>
  (state: RootState): IOrganization => {
    if (!searchOrgId) return {} as IOrganization;

    const organization = state.organizations.system.organizationsList.find(
      ({ orgId }) => orgId === searchOrgId
    );

    return {} as IOrganization;
  };

export const formatData = (orga: any) => {
  const {
    superAdministratorAvatar,
    superAdministratorFirstname,
    superAdministratorLastname,
    superAdministratorEmail,
    superAdministratorPhoneNumbers,
    superAdministratorAddresses,
    superAdministratorDepartment,
    superAdministratorDepartmentJob,

    contactPersonAvatar,
    contactPersonFirstname,
    contactPersonLastname,
    contactPersonEmail,
    contactPersonPhoneNumbers,
    contactPersonAddresses,
    contactPersonDepartment,
    contactPersonDepartmentJob,

    bpContactPersonAvatar,
    bpContactPersonFirstname,
    bpContactPersonLastname,
    bpContactPersonEmail,
    bpContactPersonPhoneNumbers,
    bpContactPersonAddresses,
    bpContactPersonDepartment,
    bpContactPersonDepartmentJob,

    ...data
  } = orga;

  return {
    ...data,
    contactPerson: {
      avatar: contactPersonAvatar,
      firstname: contactPersonFirstname,
      lastname: contactPersonLastname,
      email: contactPersonEmail,
      numbers: contactPersonPhoneNumbers,
      addresses: contactPersonAddresses,
      department: contactPersonDepartment,
      departmentJob: contactPersonDepartmentJob,
    },
    bpContactPerson: {
      avatar: bpContactPersonAvatar,
      firstname: bpContactPersonFirstname,
      lastname: bpContactPersonLastname,
      email: bpContactPersonEmail,
      numbers: bpContactPersonPhoneNumbers,
      addresses: bpContactPersonAddresses,
      department: bpContactPersonDepartment,
      departmentJob: bpContactPersonDepartmentJob,
    },
    superAdministrator: {
      avatar: superAdministratorAvatar,
      firstname: superAdministratorFirstname,
      lastname: superAdministratorLastname,
      email: superAdministratorEmail,
      numbers: superAdministratorPhoneNumbers,
      addresses: superAdministratorAddresses,
      department: superAdministratorDepartment,
      departmentJob: superAdministratorDepartmentJob,
    },
  };
};

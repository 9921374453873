import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import common_de from "./Translations/de/common.json";
import common_en from "./Translations/en/common.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false }, // React already does escaping
    debug: false,
    fallbackLng: "de",
    returnObjects: true,
    keySeparator: ".",
    react: {
      useSuspense: false,
      wait: true,
    },
    resources: {
      en: {
        common: common_en, // 'common' is our custom namespace
      },
      de: {
        common: common_de,
      },
    },
    ns: ["common"],
    defaultNS: "common",
  })
  .catch((reason) => console.error("i18n init error", reason));

export default i18n;

import { createInterceptors } from "@API/middleware";
import { AxiosInstance, HttpStatusCode } from "axios";

export const createGetOrganizationInvitedUsersRequestInterceptors = (
  organizationInvitedUsersRequest: AxiosInstance
): void => {
  createInterceptors(organizationInvitedUsersRequest, {
    responseSuccessMessage:
      "A detailed list with all organization's invitations is returned.",
    responseErrorMessage:
      "An error occurred while getting the organization users invitations data.",
    errorCallbackMessages: {
      [HttpStatusCode.Unauthorized]:
        "You need to have a valid keycloak token to access this endpoint.",
      [HttpStatusCode.Forbidden]:
        "You are not allowed to fetch this information.",
      [HttpStatusCode.ImATeapot]:
        "This device is unknown to the backend; you have to do a device specific 2FA.",
    },
  });
};

export const createPostInviteUserRequestInterceptors = (
  inviteUserRequest: AxiosInstance
): void => {
  createInterceptors(inviteUserRequest, {
    responseSuccessMessage:
      "Invitation stored and sent; detailed information for the invitation will be returned.",
    responseErrorMessage: "An error occurred while inviting a user.",
    errorCallbackMessages: {
      [HttpStatusCode.BadRequest]:
        "Either malformed input or the org user is already bound (invited and accepted).",
      [HttpStatusCode.Unauthorized]:
        "You need to have a valid keycloak token to access this endpoint.",
      [HttpStatusCode.Forbidden]: "You are not allowed to invite users.",
      [HttpStatusCode.ImATeapot]:
        "This device is unknown to the backend; you have to do a device specific 2FA.",
    },
  });
};

export const createGetInvitationDetailsRequestInterceptors = (
  invitationDetailsRequest: AxiosInstance
): void => {
  createInterceptors(invitationDetailsRequest, {
    responseSuccessMessage:
      "Detailed information for the invitation with the given id will be returned.",
    responseErrorMessage:
      "An error occurred while getting the organization users invitations data.",
    errorCallbackMessages: {
      [HttpStatusCode.Unauthorized]:
        "You need to have a valid keycloak token to access this endpoint.",
      [HttpStatusCode.Forbidden]:
        "You are not allowed to fetch this information.",
      [HttpStatusCode.NotFound]: "No invitation known with the given id.",
      [HttpStatusCode.ImATeapot]:
        "This device is unknown to the backend; you have to do a device specific 2FA.",
    },
  });
};

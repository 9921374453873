import * as React from "react";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Link, useLocation } from "react-router-dom";
import "./styles.scss";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import i18n from "i18next";
import { useAppSelector } from "@hooks";
import { RootState } from "@Store";
import { selectIsAuthenticated, selectLanguage } from "@Store/App/selectors";
import { useCallback, useEffect, useState } from "react";
import Diversity3Icon from "@mui/icons-material/Diversity3";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  position: "relative",
  height: "calc(100% - 15px)",
  overflowX: "hidden",
  backgroundColor: "#151515",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  position: "relative",
  height: "calc(100% - 15px)",
  backgroundColor: "#151515",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  position: "relative",
  whiteSpace: "nowrap",
  height: "calc(100% - 30px)",
  marginTop: "30px",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
    },
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const [navigationItems, setNavigationItems] = useState<any>([]);

  const { selectedLanguage } = useAppSelector((state: RootState) => ({
    selectedLanguage: selectLanguage(state),
  }));

  console.log(selectedLanguage);

  const getNavigationItems = useCallback(
    () => [
      {
        name: "corporate",
        displayName: i18n.t("sidebar.organization.corporate"),
        icon: <CorporateFareIcon />,
      },
      {
        name: "company-legal",
        displayName: i18n.t("sidebar.organization.policies"),
        icon: <ManageAccountsIcon />,
      },
      {
        name: "user-management",
        displayName: i18n.t("sidebar.organization.users"),
        icon: <GroupAddIcon />,
      },
      {
        name: "invitations",
        displayName: i18n.t("sidebar.organization.invitations"),
        icon: <PendingActionsIcon />,
      },
      {
        name: "teams",
        displayName: "teams",
        icon: <Diversity3Icon />,
      },
    ],
    [selectedLanguage]
  );

  useEffect(() => {
    // @ts-ignore
    setNavigationItems([...getNavigationItems()]);
  }, [selectedLanguage]);

  return (
    <Box sx={{ display: "flex", top: "20px", height: "98%" }}>
      <Drawer variant="permanent" open={open}>
        <List style={{ padding: "0" }}>
          {navigationItems.map((item: any) => (
            <ListItem key={item.name} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                component={Link}
                to={`/${item.name}`}
                className={
                  location.pathname.includes(item.name)
                    ? "active-menu-item"
                    : "passive-menu-item"
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor: location.pathname.includes(item.name)
                    ? theme.palette.warning.main
                    : "inherit",
                  color: location.pathname.includes(item.name)
                    ? "black"
                    : "white",
                  "&:hover": {
                    backgroundColor: location.pathname.includes(item.name)
                      ? theme.palette.warning.main
                      : "#333",
                    "& .MuiListItemText-root": {
                      fontWeight: "bold",
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "10px",
                    width: "30px",
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  className="sidebar-text"
                  primary={item.displayName}
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          onClick={handleDrawerToggle}
          sx={{
            width: "100%",
            alignSelf: "center",
            marginBottom: 1,
            color: "#f0f0f0",
            borderRadius: "3px",
          }}
        >
          {open ? (
            <div>
              <KeyboardDoubleArrowLeftIcon />{" "}
            </div>
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </IconButton>
      </Drawer>
    </Box>
  );
}

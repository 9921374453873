import {
  getAxiosAdminOrganizationRequest,
  getAxiosOrganizationUsersRequest,
  patchAxiosOrganizationUserRequest,
} from "@API";
import {
  createGetOrganizationUsersInterceptors,
  createPatchOrganizationUserInterceptors,
} from "@API/Users/middlewares";
import {
  IAxiosOrganizationUser,
  IGetAxiosOrganizationUsers,
  IPatchAxiosOrganizationUsers,
} from "@API/Users/types";
import {
  IOrganizationUser,
  OrganizationId,
  OrganizationUserId,
  PatchOrganizationUserBody,
} from "@Store/Organizations/types";
import { AxiosInstance, AxiosResponse } from "axios";

export const getAxiosOrganizationUsers = async (
  orgId: OrganizationId
): Promise<IGetAxiosOrganizationUsers> => {
  const organizationUsersRequest: AxiosInstance =
      getAxiosAdminOrganizationRequest();

  createGetOrganizationUsersInterceptors(organizationUsersRequest);

  const response: AxiosResponse = await getAxiosAdminOrganizationRequest().get(
    `${orgId}/users`
  );

  const organizationUsersData: IOrganizationUser[] = response.data;

  return { data: organizationUsersData };
};

export const patchAxiosOrganizationUser = async (
  organizationId: OrganizationId,
  userId: OrganizationUserId,
  body: PatchOrganizationUserBody
): Promise<IPatchAxiosOrganizationUsers> => {
  const changeOrganizationUsersRequest: AxiosInstance =
    patchAxiosOrganizationUserRequest();

  createPatchOrganizationUserInterceptors(changeOrganizationUsersRequest);

  const response: AxiosResponse = await changeOrganizationUsersRequest.patch(
    `${organizationId}/users/${userId}`,
    body
  );

  const changedOrganizationUserData: IOrganizationUser = response.data;

  return { data: changedOrganizationUserData };
};

export const putAxiosOrganizationUser = async (
  orgId: OrganizationId,
  body: IOrganizationUser
): Promise<IAxiosOrganizationUser> => {
  // const response = await getApiRequest().put(`/organizations/${orgId}/organization-users/${body.user.id}/`, formatBody(body));

  return { data: body };
};

export const postAxiosOrganizationUser = async (
  orgId: OrganizationId,
  body: IOrganizationUser
): Promise<IAxiosOrganizationUser> => {
  // const response = await getApiRequest().post(`/organizations/${orgId}/organization-users/${body.user.id}/`, formatBody(body));

  return {
    data: body,
  };
};

export const deleteAxiosOrganizationUser = async (
  orgId: OrganizationId,
  body: IOrganizationUser
): Promise<{}> => {
  // TODO: api call
  // const response = await getApiRequest().delete(`/organizations/${orgId}/organization-users/${body.user.id}/`);

  return {};
};

import React from "react";
import "./styles.scss";

interface InfoLabelProps {
  label: string;
  value: React.ReactNode;
}

const InfoLabel: React.FC<InfoLabelProps> = ({ label, value }) => (
  <div className="info-label">
    <span className="info-label__title">{label}:</span>
    <span className="info-label__value">{value}</span>
  </div>
);

export default InfoLabel;

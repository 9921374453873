import { createAction } from "@reduxjs/toolkit";
import { OrganizationId } from "@Store/Organizations/types";
import {
  IDeleteUserInviteRequest,
  IInviteUserModalType,
  INotifyUserInviteRequest,
  InvitationId,
  IUserInvite,
  PostInviteUserBody,
} from "./types";

export const inviteUsersListRequest = createAction<OrganizationId>(
  "INVITE_USERS_LIST/REQUEST"
);
export const inviteUsersListSuccess = createAction<IUserInvite[]>(
  "INVITE_USERS_LIST/SUCCESS"
);
export const inviteUsersListFail = createAction<Error>(
  "INVITE_USERS_LIST/FAIL"
);

export const invitationDetailsRequest = createAction<InvitationId>(
  "INVITATION_DETAILS/REQUEST"
);
export const invitationDetailsSuccess = createAction<IUserInvite>(
  "INVITATION_DETAILS/SUCCESS"
);
export const invitationDetailsFail = createAction<Error>(
  "INVITATION_DETAILS/FAIL"
);

export const createInviteUserRequest = createAction<PostInviteUserBody>(
  "CREATE_INVITE_USER/REQUEST"
);
export const createInviteUserSuccess = createAction<IUserInvite>(
  "CREATE_INVITE_USER/SUCCESS"
);
export const createInviteUserFail = createAction<Error>(
  "CREATE_INVITE_USER/FAIL"
);

// not used yet
export const resetInviteUserRequest = createAction<void>(
  "RESET_INVITE_USER/REQUEST"
);
export const resetInviteUserSuccess = createAction<void>(
  "RESET_INVITE_USER/SUCCESS"
);
export const resetInviteUserFail = createAction<Error>(
  "RESET_INVITE_USER/FAIL"
);

export const deleteInviteUserRequest = createAction<void>(
  "DELETE_INVITE_USER/REQUEST"
);
export const deleteInviteUserSuccess = createAction<IDeleteUserInviteRequest>(
  "DELETE_INVITE_USER/SUCCESS"
);
export const deleteInviteUserFail = createAction<Error>(
  "DELETE_INVITE_USER/FAIL"
);

export const notifyInviteUserRequest = createAction<void>(
  "NOTIFY_INVITE_USERS/REQUEST"
);
export const notifyInviteUserSuccess = createAction<INotifyUserInviteRequest>(
  "NOTIFY_INVITE_USERS/SUCCESS"
);
export const notifyInviteUserFail = createAction<Error>(
  "NOTIFY_INVITE_USERS/FAIL"
);

export const setModalInviteUser = createAction<void>("SET_MODAL_INVITE_USERS");
export const setModalInviteUserType = createAction<IInviteUserModalType>(
  "SET_MODAL_INVITE_USER_TYPE"
);
export const resetInviteUserModal = createAction<void>(
  "MODAL_INVITE_USER_RESET"
);

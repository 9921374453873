import "./index.scss";

import { ReactNode } from "react";

export type BlackpinTagProps = {
  text: string | ReactNode;
  hidden?: boolean;
  color?: "Green" | "Orange" | "Red" | "default";
};

function BlackPinTag(props: BlackpinTagProps) {
  const { text, hidden, color }: BlackpinTagProps = props;

  return (
    <div
      hidden={hidden}
      className="app__blackPinTag"
      style={{
        borderColor: !color || color === "default" ? "white" : "",
        borderWidth: !color || color === "default" ? 1 : "",
        borderStyle: !color || color === "default" ? "solid" : "",
        backgroundColor:
          color === "Green"
            ? "#91AF55"
            : color === "Red"
            ? "#C4482B"
            : color === "Orange"
            ? "#E7B14F"
            : "transparent",
      }}
    >
      <label
        className="label"
        style={{
          color:
            color === "Green"
              ? "white"
              : color === "Red"
              ? "white"
              : color === "Orange"
              ? "black"
              : "white",
        }}
      >
        {text}
      </label>
    </div>
  );
}

export default BlackPinTag;

import { IMatrixServer } from "@API/Matrix/types";
import { createAction } from "@reduxjs/toolkit";

export const matrixServersListRequest = createAction<void>(
  "MATRIX_SERVERS_LIST/REQUEST"
);
export const matrixServersListSuccess = createAction<IMatrixServer[]>(
  "MATRIX_SERVERS_LIST/SUCCESS"
);
export const matrixServersListFail = createAction<Error>(
  "MATRIX_SERVERS_LIST/FAIL"
);

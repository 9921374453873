import { RootState } from "@Store";
import { selectAppUserData } from "@Store/AppUser/selectors";
import { IAppUser, IAppUserMembership } from "@Store/AppUser/types";
import { isOrganizationUserSimpleUser } from "@Store/Organizations/utilities";
import { createSelector } from "@reduxjs/toolkit";
import {
  CreateOrganizationModal,
  IOrganization,
  OrganizationId,
  OrganizationRole,
} from "./types";

export interface IOrganizationsGeneralData {
  isLoading: boolean;
  success: boolean;
  fail: boolean;
  noOrganization: boolean;
}

export const selectOrganizationsGeneralData = (
  state: RootState
): IOrganizationsGeneralData => {
  const { isLoading, success, fail, noOrganization } =
    state.organizations.system;

  return {
    isLoading,
    success,
    fail,
    noOrganization,
  };
};

export const selectCurrentOrgId = (
  state: RootState
): OrganizationId | undefined =>
  state.organizations.system.selectedOrganizationId;

export const selectOrganizationsList = (state: RootState): IOrganization[] =>
  state.organizations.system.organizationsList;

export const selectAllowedOrganizationsList = createSelector(
  selectOrganizationsList,
  selectAppUserData,
  (organizations: IOrganization[], appUser: IAppUser) => {
    const filteredOrgIds: Set<OrganizationId> = new Set(
      appUser.memberships
        .filter(
          (membership: IAppUserMembership) =>
            !isOrganizationUserSimpleUser(membership.orgUser.role)
        )
        .map((membership: IAppUserMembership) => membership.orgId)
    );

    return organizations.filter((organization: IOrganization) =>
      filteredOrgIds.has(organization.orgId)
    );
  }
);

export const selectCurrentOrganization = createSelector(
  (state: RootState) => state.organizations.system.currentOrganization,
  (currentOrganization: IOrganization | null) =>
    currentOrganization ?? ({} as IOrganization)
);

export const selectOrganizationPolicyData = (state: RootState) =>
  state.organizations.system.organizationPolicy.data;

export const selectCurrentOrganizationRole = createSelector(
  selectAppUserData,
  selectCurrentOrgId,
  (currentUser: IAppUser, organizationId: OrganizationId | undefined) => {
    const currentOrganization: IAppUserMembership | undefined =
      currentUser.memberships.find(
        (membership: IAppUserMembership) => membership.orgId === organizationId
      );

    return currentOrganization?.orgUser.role ?? "SIMPLE_USER";
  }
);

export const selectIsAdminOfCurrentOrganization = createSelector(
  selectCurrentOrganizationRole,
  (role: OrganizationRole) => role === "ADMIN" || role !== "ORG_ADMIN"
);

export const selectIsSimpleUserOfCurrentOrganization = createSelector(
  selectCurrentOrganizationRole,
  (role: OrganizationRole) => role === "SIMPLE_USER"
);

export const selectIsAdminOfAtLeaseOneOrganization = createSelector(
  selectAppUserData,
  (appUser: IAppUser) => {
    return appUser.memberships.some(
      (membership: IAppUserMembership) =>
        membership.orgUser.role !== "SIMPLE_USER"
    );
  }
);

export const selectCreateUpdateOrganizationStatus = (state: RootState) =>
  state.organizations.system.updateStatus;

export const selectUpdateOrganizationStatus = (state: RootState) =>
  state.organizations.system.updateStatus;

export const selectSelectedOrganization = createSelector(
  selectOrganizationsList,
  selectCurrentOrgId,
  (organizations, selectedOrgId) =>
    organizations.find((org) => org.orgId === selectedOrgId)
);

// @ts-ignore
export const selectBPContactPerson = createSelector(
  selectCurrentOrganization,
  (currentOrganization: IOrganization) => {
    return {
      avatar: undefined,
      email: "anna.timmerson@client.de",
      firstname: "Anna",
      lastname: "Timmerson",
      department: undefined,
      departmentJob: undefined,
      addresses: [],
      phoneNumbers: [
        {
          id: 1,
          number: "175 945 57 54",
          country: {
            id: 1,
            isoCode: "DE",
            name: "germany",
            phoneAreaCode: "+49",
          },
          phoneNumberType: {
            id: 5,
            name: "main",
          },
        },
      ],
    };
  }
);
// export const selectBPContactPerson = createSelector(
//   selectCurrentOrganization,
//   (currentOrganization: IOrganization) => currentOrganization.bpContactPerson
// );

// @ts-ignore
// export const selectContactPerson = createSelector(
//   selectCurrentOrganization,
//   (currentOrganization: IOrganization) => currentOrganization.contactPerson
// );

export const selectContactPerson = createSelector(
  selectCurrentOrganization,
  (currentOrganization: IOrganization) => {
    return {
      avatar: undefined,
      email: "anna.timmerson@client.de",
      firstname: "Anna",
      lastname: "Timmerson",
      department: undefined,
      departmentJob: undefined,
      addresses: [],
      phoneNumbers: [
        {
          id: 1,
          number: "175 945 57 54",
          country: {
            id: 1,
            isoCode: "DE",
            name: "germany",
            phoneAreaCode: "+49",
          },
          phoneNumberType: {
            id: 5,
            name: "main",
          },
        },
      ],
    };
  }
);

export const selectCreateOrganizationModal = (
  state: RootState
): CreateOrganizationModal =>
  state.organizations.system.createOrganizationModal;

export const selectShowCreateOrganizationModal = (state: RootState): boolean =>
  state.organizations.system.createOrganizationModal.type === "create";


import { takeLatest, call, put } from 'redux-saga/effects';
import {
  masterDataRequest, masterDataSuccess, masterDataFail,
} from './actions';
import { getAxiosMasterData, IGetAxiosMasterData } from '@API/MasterData';



function* onMasterDataRequest() {
  try {
    const response: IGetAxiosMasterData = yield call(getAxiosMasterData);
    if (response.error) throw response.error;

    yield put(masterDataSuccess(response.data));
  } catch (e) {
    console.error(e)
    yield put(masterDataFail(e as Error));
  }
}


export default function* () {
  yield takeLatest(masterDataRequest, onMasterDataRequest);
}
import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LanguageSelectAction from "@Components/LanguageSelectAction";

interface IHeader {
  showCurrentOrganizationName?: boolean;
  onLogoClick?: () => void;
}

const useStyles = makeStyles(() => ({
  header: {
    position: "absolute",
    top: "30px",
    right: "40px",
    border: "none",
  },
}));

const UnAuthenticatedHeader: React.FC<IHeader> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <LanguageSelectAction />
    </Box>
  );
};

export default UnAuthenticatedHeader;

import React from "react";
import { Card, CardContent, CardActions, Typography } from "@mui/material";
import InfoLabel from "@Components/InfoLabel/InfoLabel";
import "./styles.scss";

const OrganizationInfoCard: React.FC = () => (
  <Card className="single-organization-content-card">
    <div className="title">Organization</div>
    <CardContent className="card-content">
      <div className="organization-details">
        <img
          src={`${process.env.PUBLIC_URL}/Assets/organization-image-mock.jpeg`}
          className="organization-image"
        />
        <Typography className="value">Koch LLC</Typography>
      </div>
      <InfoLabel label="Admin" value="Craig Ankunding" />
      <InfoLabel label="Creation Date" value="12.07.2023" />
      <InfoLabel label="Last Update" value="12.07.2023" />
      <InfoLabel label="by User" value="Shkelqim Maxharraj" />
    </CardContent>
    <CardActions className="card-actions">
      <div className="info">
        <span className="info-title">Info:</span>
        <span className="info-content">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata. Lorem ipsum dolor
          sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.
        </span>
      </div>
    </CardActions>
  </Card>
);

export default OrganizationInfoCard;

import React from "react";
import { useAppSelector } from "@hooks";
import { RootState } from "@Store";
import { selectAppUserData } from "@Store/AppUser/selectors";
import {
  AppBar,
  Box,
  IconButton,
  OutlinedInput,
  Popover,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import LanguageSelectAction from "@Components/LanguageSelectAction";
import OrganizationDropdown from "@Components/OrganizationDropdown";
import { logoutRequest } from "@Store/App/actions";
import { useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";

interface IHeader {
  showCurrentOrganizationName?: boolean;
  onLogoClick?: () => void;
  onOrgSelect?: (orgId: string) => void;
}

const StyledHeader = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const StyledUserName = styled("span")(({ theme }) => ({
  fontSize: "14px",
  marginLeft: "20px",
  marginRight: "10px",
  color: theme.palette.primary.main,
}));

const Header: React.FC<IHeader> = ({
  showCurrentOrganizationName = true,
  onLogoClick = () => {},
  onOrgSelect,
}) => {
  const { appUser } = useAppSelector((state: RootState) => ({
    appUser: selectAppUserData(state),
  }));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    sessionStorage.clear();
    dispatch(logoutRequest());
    window.open("/");
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);

  const renderMenu = (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    ></Popover>
  );

  return (
    <StyledHeader position="static">
      <Toolbar style={{ paddingLeft: "20px" }}>
        <Box sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
          <OrganizationDropdown onOrgSelect={onOrgSelect} />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
          }}
        ></Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div>
            <StyledUserName>{appUser?.displayName}</StyledUserName>
            <LanguageSelectAction
              styles={{ backgroundColor: "black", color: "white" }}
              svgStyles={{ color: "white" }}
            />
            {appUser?.userId && (
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <LogoutIcon style={{ color: "#fff", marginInline: "10px" }} />
              </IconButton>
            )}
          </div>
        </Box>
      </Toolbar>
      {renderMenu}
    </StyledHeader>
  );
};

export default Header;

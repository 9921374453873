import {
  deleteAxiosInviteUser,
  getAxiosInvitationDetails,
  getAxiosOrganizationInvitedUsers,
  postAxiosInviteUser,
  putAxiosInviteUserNotify,
} from "@API/Invites/endpoints";
import {
  IAxiosInviteUser,
  IGetAxiosInvitationDetails,
  IGetAxiosOrganizationInvitedUsers,
} from "@API/Invites/types";
import { selectCurrentOrgId } from "@Store/Organizations/selectors";
import { OrganizationId } from "@Store/Organizations/types";
import i18n from "@i18n";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosError, isAxiosError } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  createInviteUserFail,
  createInviteUserRequest,
  createInviteUserSuccess,
  deleteInviteUserFail,
  deleteInviteUserRequest,
  deleteInviteUserSuccess,
  invitationDetailsFail,
  invitationDetailsRequest,
  invitationDetailsSuccess,
  inviteUsersListFail,
  inviteUsersListRequest,
  inviteUsersListSuccess,
  notifyInviteUserFail,
  notifyInviteUserRequest,
  notifyInviteUserSuccess,
  resetInviteUserModal,
} from "./actions";
import { selectInviteUsersIds } from "./selectors";
import { IBodyInviteUserIds, InvitationId, PostInviteUserBody } from "./types";
import { showSnackbar } from "@Store/toast/actions";

function* onInviteUsersListRequest({ payload }: PayloadAction<OrganizationId>) {
  try {
    const response: IGetAxiosOrganizationInvitedUsers = yield call(
      getAxiosOrganizationInvitedUsers,
      payload
    );
    if (response.error) throw response.error;

    yield put(inviteUsersListSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(inviteUsersListFail(e as Error));
  }
}

function* onInvitationDetailsRequest({ payload }: PayloadAction<InvitationId>) {
  try {
    const response: IGetAxiosInvitationDetails = yield call(
      getAxiosInvitationDetails,
      payload
    );

    if (response.error) throw response.error;

    yield put(invitationDetailsSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(invitationDetailsFail(e as Error));
  }
}

function* onCreateInviteUserRequest({
  payload,
}: PayloadAction<PostInviteUserBody>) {
  try {
    const response: IAxiosInviteUser = yield call(postAxiosInviteUser, payload);

    if (response.error) throw response.error;

    yield put(resetInviteUserModal());
    yield put(createInviteUserSuccess(response.data));

    yield put(showSnackbar(i18n.t("inviteUser.inviteUserSuccess"), "success"));

    yield put(invitationDetailsRequest(response.data.invitationId));
  } catch (e) {
    const errorMessage =
      isAxiosError(e) && e.response
        ? e.response.data.message
        : (e as AxiosError).message;
    yield put(
      showSnackbar(errorMessage || i18n.t("inviteUser.inviteUserFail"), "error")
    );
    yield put(createInviteUserFail(e as Error));
  }
}

function* onDeleteInviteUserRequest() {
  try {
    const orgId: OrganizationId = yield select(selectCurrentOrgId);
    const body: IBodyInviteUserIds = yield select(selectInviteUsersIds);

    const response: IAxiosInviteUser = yield call(deleteAxiosInviteUser, {
      orgId,
      body,
    });
    if (response.error) throw response.error;

    yield put(resetInviteUserModal());
    yield put(deleteInviteUserSuccess({ orgId, body }));
  } catch (e) {
    console.error(e);
    yield put(deleteInviteUserFail(e as Error));
  }
}

function* onNotifyInviteUserRequest() {
  try {
    const orgId: OrganizationId = yield select(selectCurrentOrgId);
    const body: IBodyInviteUserIds = yield select(selectInviteUsersIds);

    const response: IAxiosInviteUser = yield call(putAxiosInviteUserNotify, {
      orgId,
      body,
    });
    if (response.error) throw response.error;

    yield put(resetInviteUserModal());
    yield put(notifyInviteUserSuccess({ orgId, body }));
  } catch (e) {
    console.error(e);
    yield put(notifyInviteUserFail(e as Error));
  }
}

export default function* () {
  yield takeLatest(inviteUsersListRequest, onInviteUsersListRequest);
  yield takeLatest(invitationDetailsRequest, onInvitationDetailsRequest);
  yield takeLatest(createInviteUserRequest, onCreateInviteUserRequest);
  yield takeLatest(deleteInviteUserRequest, onDeleteInviteUserRequest);
  yield takeLatest(notifyInviteUserRequest, onNotifyInviteUserRequest);
  yield takeLatest(resetInviteUserModal, () => {});
  yield takeLatest(resetInviteUserModal, () => {});
}

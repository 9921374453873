import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, IconButton, InputAdornment, Switch } from "@mui/material";
import CustomTable, { Column, Data } from "@Components/Table";
import { useAppSelector } from "@hooks";
import { RootState } from "@Store";
import { selectCurrentOrganization } from "@Store/Organizations/selectors";
import AddPlusIcon from "@src/Constants/svg/AddPlusIcon";
import "./styles.scss";
import CreateEditTeamModal from "@Components/CreateEditTeamsModal/create-edit-teams-modal";
import { resetSingleTeam, teamsListRequest } from "@Store/Team/actions";
import ModalBulkUploadTeams from "@Components/BulkImportTeams/bulk-import-teams-modal";
import { useTranslation } from "react-i18next";
import { StyledOutlinedInput } from "@Pages/AdminPages/UsersManagement";
import SearchIcon from "@mui/icons-material/Search";

const TeamsManagementPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<any>(null);
  const [isBulkUploadModalVisible, setIsBulkUploadModalVisible] =
    useState(false);

  const dispatch = useDispatch();

  const { currentOrganization } = useAppSelector((state: RootState) => ({
    currentOrganization: selectCurrentOrganization(state),
  }));

  const { teams } = useAppSelector((state: RootState) => ({
    teams: state.teams.list,
  }));

  useEffect(() => {
    const selectedOrgId = currentOrganization?.orgId;
    selectedOrgId && dispatch(teamsListRequest(selectedOrgId));
  }, [dispatch, currentOrganization?.orgId]);

  const handleOpenCreateModal = () => {
    setSelectedTeam(null);
    setIsCreateModalOpen(true);
  };

  const handleOpenEditModal = (team: any) => {
    setSelectedTeam(team);
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setSelectedTeam(null);
    setIsCreateModalOpen(false);
    dispatch(resetSingleTeam());
  };

  const showBulkUploadModal = () => setIsBulkUploadModalVisible(true);
  const hideBulkUploadModal = () => setIsBulkUploadModalVisible(false);

  const columns: Column[] = [
    {
      field: "name",
      headerName: t("teamManagement.nameLabel"),
      sortable: true,
    },
    {
      field: "description",
      headerName: t("teamManagement.descriptionLabel"),
      sortable: false,
    },
    {
      field: "ownerFullName",
      headerName: t("teamManagement.teamChatOwnerLabel"),
      sortable: false,
    },
    {
      field: "memberCount",
      headerName: t("teamManagement.memberCount"),
      sortable: true,
    },
    {
      field: "chatEnabled",
      headerName: t("teamManagement.chatEnabledLabel"),
      sortable: false,
    },
  ];

  const handleRowClick = (row: any) => {
    handleOpenEditModal(row);
  };

  const data: Data[] = teams
    .filter((team) =>
      `${team.orgId} ${team.name} ${team.description} ${team.ownerUserId} ${team.type}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .map((team) => ({
      id: team.id,
      orgId: team.orgId,
      name: team.name,
      description: (
        <Box
          component="span"
          aria-label={team.description}
          title={team.description}
        >
          {team.description.length > 30
            ? `${team.description.substring(0, 30)}...`
            : team.description}
        </Box>
      ),
      ownerUserId: team.ownerUserId,
      ownerFullName: team.ownerFullName,
      type: team.type,
      memberCount: team.memberCount,
      code: team.code,
      chatEnabled: (
        <Switch
          checked={team.chatEnabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      ),
    }));

  const menuItems = [
    {
      name: t("buttons.edit"),
      onClick: (row: Data) => {
        handleRowClick(row);
      },
    },
  ];

  return (
    <Box className="root">
      <div className="clients-organisation-header">
        <h2>{t("teamManagement.title")}</h2>
        <StyledOutlinedInput
          id="outlined-adornment-search"
          type={"text"}
          placeholder="Search..."
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="search icon" edge="start">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => setSearchQuery(e.target.value)}
          label="Search"
        />

        <div>
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: "4px",
            }}
            onClick={handleOpenCreateModal}
          >
            <AddPlusIcon />{" "}
            <span
              style={{ marginLeft: "10px", fontWeight: "600", color: "#000" }}
            >
              {t("teamManagement.addNewTeam")}
            </span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              marginLeft: "10px",
              borderRadius: "4px",
            }}
            onClick={showBulkUploadModal}
          >
            <AddPlusIcon />{" "}
            <span
              style={{ marginLeft: "10px", fontWeight: "600", color: "#000" }}
            >
              {t("teamManagement.bulkImportTeams")}
            </span>
          </Button>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          marginBottom: "20px",
        }}
      ></div>
      <CustomTable
        columns={columns}
        selectable={false}
        menuItems={menuItems}
        data={data}
        rowsPerPage={10}
      />

      {isCreateModalOpen && (
        <CreateEditTeamModal
          open={isCreateModalOpen}
          handleClose={handleCloseCreateModal}
          teamData={selectedTeam}
        />
      )}

      <ModalBulkUploadTeams
        visible={isBulkUploadModalVisible}
        onClose={hideBulkUploadModal}
      />
    </Box>
  );
};

export default TeamsManagementPage;

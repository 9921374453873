import { getAxiosMatrixServers } from "@API/Matrix/endpoints";
import { IGetAxiosMatrixServers } from "@API/Matrix/types";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  matrixServersListFail,
  matrixServersListRequest,
  matrixServersListSuccess,
} from "./actions";

function* onMatrixServersListRequest() {
  try {
    const matrixServersResponse: IGetAxiosMatrixServers = yield call(
      getAxiosMatrixServers
    );

    if (matrixServersResponse.error) throw matrixServersResponse.error;

    yield put(matrixServersListSuccess(matrixServersResponse.data));
  } catch (e) {
    console.error(e);
    yield put(matrixServersListFail(e as Error));
  }
}

export default function* () {
  yield takeLatest(matrixServersListRequest, onMatrixServersListRequest);
}

import { createInterceptors } from "@API/middleware";
import { AxiosInstance, HttpStatusCode } from "axios";

export const createPostLoginRequestInterceptors = (
  authRequest: AxiosInstance
): void => {
  createInterceptors(authRequest, {
    responseSuccessMessage: "User is logged-in successfully.",
    responseErrorMessage: "An error occurred while logging in.",
    errorCallbackMessages: {
      [HttpStatusCode.BadRequest]:
        "The given login parameters do not fit the requirements.",
      [HttpStatusCode.Unauthorized]: "You provided invalid credentials.",
      [HttpStatusCode.Forbidden]: "You need to activate your account first.",
    },
  });
};

export const createPostAxiosTokenRefreshInterceptors = (
  authRequest: AxiosInstance
): void => {
  createInterceptors(authRequest, {
    responseSuccessMessage: "Token is refreshed successfully.",
    responseErrorMessage: "An error occurred while refreshing the token.",
    errorCallbackMessages: {
      [HttpStatusCode.BadRequest]:
        "The given login parameters do not fit the requirements.",
      [HttpStatusCode.Unauthorized]: "You provided invalid credentials.",
    },
  });
};

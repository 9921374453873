import { createInterceptors } from "@API/middleware";
import { AxiosInstance, HttpStatusCode } from "axios";

export const createGetAppUserInterceptors = (
  apiRequest: AxiosInstance
): void => {
  createInterceptors(apiRequest, {
    responseSuccessMessage: "Received information about the calling main-user.",
    responseErrorMessage:
      "An error occurred while fetching information about the main-user.",
    errorCallbackMessages: {
      [HttpStatusCode.Unauthorized]:
        "A caller needs to be logged in to call this endpoint.",
      [HttpStatusCode.Forbidden]: "You are not allowed to call this API.",
    },
  });
};

import React from "react";
import "./styles.scss";

interface Statistic {
  label: string;
  value: number | string;
}

const statistics: Statistic[] = [
  { label: "active users", value: 2076 },
  { label: "inactive users", value: 124 },
  { label: "docs", value: 2500 },
  { label: "images", value: "12.843" },
  { label: "rooms", value: 12 },
  { label: "translation carter", value: "245.987" },
];

const OrganizationStatistics: React.FC = () => {
  return (
    <div className="statistics">
      <span
        className="info-label"
        style={{ color: "#9E9E9E", fontSize: "14px", marginBottom: "20px" }}
      >
        Statistic:
      </span>
      <div className="stats-grid">
        <div className="stats-column">
          {statistics.slice(0, 3).map((stat, index) => (
            <div key={index} className="stat-item">
              <span className="stat-value">
                {stat.value + " "}
                {stat.label}
              </span>
            </div>
          ))}
        </div>
        <div className="stats-column">
          {statistics.slice(3).map((stat, index) => (
            <div key={index} className="stat-item">
              <span className="stat-value">
                {stat.value + " "} {stat.label}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrganizationStatistics;

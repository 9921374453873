import { IMasterData } from "@Store/MasterData/types";
import { MasterDataRes } from "./_mockData";

export interface IGetAxiosMasterData {
  data: IMasterData;
  error?: any;
}

export const getAxiosMasterData = async (): Promise<IGetAxiosMasterData> => {
  // TODO: go request when route in backend
  // const response = await getApiRequest().get('/masterData');

  return { data: MasterDataRes };
};

import {
  OrganizationRole,
  OrganizationUserStatus,
} from "@Store/Organizations/types";

export const isOrganizationUserSuperAdmin = (role: OrganizationRole): boolean =>
  role === "ORG_ADMIN";

export const isOrganizationUserAdmin = (role: OrganizationRole): boolean =>
  role === "ADMIN";

export const isOrganizationUserSimpleUser = (role: OrganizationRole): boolean =>
  role === "SIMPLE_USER";

export const roleToReadable = (role: OrganizationRole): string => {
  switch (role) {
    case "SIMPLE_USER":
      return "organizationUsers.role.simpleUser";
    case "ADMIN":
      return "organizationUsers.role.admin";
    case "ORG_ADMIN":
      return "organizationUsers.role.orgAdmin";
  }
};

export const isOrganizationUserStatusActive = (
  status: OrganizationUserStatus
): boolean => status === "ACTIVE";



export const isOrganizationUserStatusActiveInvited = (
    status: OrganizationUserStatus
): boolean => status === "ACTIVE" || status === "INVITED";

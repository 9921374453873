import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
    input: {
        backgroundColor: '#000000',
        color: '#FFFFFF',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#FFFFFF',
            },
            '&:hover fieldset': {
                borderColor: '#FFFFFF',
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-error fieldset': {
                borderColor: '#FF0000',
            },
            '&.Mui-error:hover fieldset': {
                borderColor: '#FF0000',
            },
            fontSize: '16px',
            height: '45px',
            marginBottom: '8px',
            borderRadius: '5px',
        },
    },
    inputLabel: {
        color: '#9E9E9E',
        fontSize: '14px',
        textAlign: 'left',
        display: 'block',
        marginTop: '4px',
    },
    iconButton: {
        color: '#FFFFFF !important',
    },
    button: {
        height: '45px',
        fontSize: '16px',
    },
}));
import { IMatrixServer } from "@API/Matrix/types";
import {
  combineReducers,
  createReducer,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  matrixServersListFail,
  matrixServersListRequest,
  matrixServersListSuccess,
} from "./actions";

interface IMatrixInitialState {
  list: IMatrixServer[];
  isLoading: boolean;
  success: boolean;
  fail: boolean;
}

const MATRIX_INITIAL_STATE: IMatrixInitialState = {
  list: [],
  isLoading: false,
  success: false,
  fail: false,
};

const matrixServersListReducer = createReducer(MATRIX_INITIAL_STATE, {
  [matrixServersListRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [matrixServersListSuccess.type]: (
    state,
    { payload }: PayloadAction<IMatrixServer[]>
  ) => ({
    ...state,
    list: payload,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [matrixServersListFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),
});

export interface IGeneralState {
  readonly general: IMatrixInitialState;
}

export default combineReducers<IGeneralState>({
  general: matrixServersListReducer,
});

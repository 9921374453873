import { createAction } from "@reduxjs/toolkit";
import { ICreateTeamPayload, ITeam } from "@API/Teams/types";

export const teamsListRequest = createAction<string>("TEAMS_LIST/REQUEST");
export const teamsListSuccess = createAction<ITeam[]>("TEAMS_LIST/SUCCESS");
export const teamsListFail = createAction<Error>("TEAMS_LIST/FAIL");

export const singleTeamRequest = createAction<{
  orgId: string;
  id: string;
}>("SINGLE_TEAM/REQUEST");
export const singleTeamSuccess = createAction<any>("SINGLE_TEAM/SUCCESS");
export const singleTeamFail = createAction<Error>("SINGLE_TEAM/FAIL");

export const resetSingleTeam = createAction("SINGLE_TEAM/RESET");

export const createTeamRequest = createAction<{
  orgId: string;
  payload: any;
}>("CREATE_TEAM/REQUEST");
export const createTeamSuccess = createAction<ITeam>("CREATE_TEAM/SUCCESS");
export const createTeamFail = createAction<Error>("CREATE_TEAM/FAIL");

export const updateTeamRequest = createAction<{
  teamId: string;
  orgId: string;
  payload: any;
}>("UPDATE_TEAM/REQUEST");
export const updateTeamSuccess = createAction<ITeam>("UPDATE_TEAM/SUCCESS");
export const updateTeamFail = createAction<Error>("UPDATE_TEAM/FAIL");

import React from "react";
import { Card, CardContent, CardActions } from "@mui/material";
import ProgressBar from "@Components/ProgressBar";
import OrganizationStatistics from "@Components/OrganizationStatistics";

import "./styles.scss";

const ServerInfoCard: React.FC = () => (
  <Card className="single-organization-content-card">
    <div className="title">Server</div>
    <CardContent className="card-content" style={{ margin: "0 10px" }}>
      <div className="server-details">
        <div className="server-info">
          <span className="info-label">Type:</span>
          <span className="info-label-dedicated">
            DEDICATED (Wilbert Price)
          </span>
          <span className="server-url">
            https://msxrsvgwc1.blayxc566654_ju_kopdfsfsfckpin.io
          </span>
        </div>
        <div className="server-storage">
          <span className="info-label">Storage:</span>
          <div style={{ paddingBlock: "15px" }}>
            <ProgressBar value={50} />
          </div>
          <span>35%</span>
        </div>
      </div>
    </CardContent>
    <CardActions className="card-actions">
      <div className="server-description">
        <span
          className="info-label"
          style={{ color: "#9E9E9E", fontSize: "14px", marginBottom: "21px" }}
        >
          Info text:
        </span>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et.
      </div>
      <OrganizationStatistics />
    </CardActions>
  </Card>
);

export default ServerInfoCard;

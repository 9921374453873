import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import PhoneIcon from "@mui/icons-material/Phone";

enum Role {
  SYSTEM_ADMIN = "System Admin",
  ADMIN = "Admin",
  USER = "User",
  SUPPORT_USER = "Support User",
  SUPPORT_CENTER = "Support Center",
}

type RoleProps = {
  role: Role;
};

const roleStyles = {
  [Role.SYSTEM_ADMIN]: {
    backgroundColor: "#D14423",
    borderRadius: "20px",
    color: "white",
  },
  [Role.ADMIN]: {
    backgroundColor: "#D1A623",
    borderRadius: "20px",
    color: "white",
  },
  [Role.USER]: {
    backgroundColor: "#787878",
    borderRadius: "20px",
    color: "white",
  },
  [Role.SUPPORT_USER]: {
    backgroundColor: "#A8A8A8",
    borderRadius: "20px",
    color: "white",
  },
  [Role.SUPPORT_CENTER]: {
    backgroundColor: "#D14423",
    borderRadius: "20px 20px 20px 0px",
    color: "white",
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
  },
};

const RoleBox = styled(Box)<{ role: Role }>(({ role }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "30px",
  padding: "0 13px",
  width: "150px",
  ...roleStyles[role],
}));

const RoleComponent: React.FC<RoleProps> = ({ role }) => (
  <RoleBox role={role}>
    {role === Role.SUPPORT_CENTER && <PhoneIcon sx={{ marginRight: "8px" }} />}
    <Typography sx={{ fontSize: "14px" }}>{role}</Typography>
  </RoleBox>
);

export default RoleComponent;
export { Role };

import { combineReducers, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { masterDataRequest, masterDataSuccess, masterDataFail } from './actions';
import { IMasterData } from './types';

interface IMasterDataInitialState extends IMasterData {
  isLoading: boolean;
  success: boolean;
  fail: boolean;
}

const APP_USER_INITIAL_STATE: IMasterDataInitialState = {
  countries: [],
  addressTypes: [],
  phoneNumberTypes: [],
  roles: [],
  teamRoles: [],
  isLoading: false,
  success: false,
  fail: false,
};

const masterDataReducer = createReducer(APP_USER_INITIAL_STATE, {
  [masterDataRequest.type]: state => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [masterDataSuccess.type]: (state, { payload }: PayloadAction<IMasterData>) => ({
    ...state,
    ...payload,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [masterDataFail.type]: state => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),
});

export interface IGeneralState {
  readonly general: IMasterDataInitialState;
}

export default combineReducers<IGeneralState>({
  general: masterDataReducer,
});

import React from "react";
import { Box, Button, Typography, Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";

interface MembersSectionProps {
  users: { userId: string; givenName: string; surName: string }[];
  handleOpenSelectUsersModal: () => void;
  onRemoveMember: (userId: string) => void;
}

const MembersSection: React.FC<MembersSectionProps> = ({
  users,
  handleOpenSelectUsersModal,
  onRemoveMember,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mb: 2,
        height: "300px",
        overflowY: "scroll",
        paddingRight: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="body2" sx={{ mb: 1 }}>
          {t("teamManagement.membersLabel")}:
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            padding: "5px 10px ",
          }}
          onClick={handleOpenSelectUsersModal}
        >
          {t("teamManagement.addMemberButton")}
        </Button>
      </Box>
      {users.map((user) => (
        <Box
          key={user.userId}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar sx={{ marginRight: "10px" }}>
              {user.givenName.charAt(0)}
            </Avatar>
            <Typography>{`${user.givenName} ${user.surName}`}</Typography>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            sx={{ padding: "5px 8px" }}
            onClick={() => onRemoveMember(user.userId)}
          >
            {t("teamManagement.removeButton")}
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default MembersSection;

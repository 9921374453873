import { RootState } from "@Store";
import { lang } from "./types";

export const selectLogoutSuccess = (store: RootState): boolean =>
  store.app.logout.success;

export const selectIsAuthenticated = (store: RootState): boolean =>
  (store.app.login.success && !store.app.login.fail) ||
  Boolean(
    localStorage.getItem("accessToken") && localStorage.getItem("refreshToken")
  );

export const selectLanguage = (store: RootState): lang =>
  store.app.general.language;

export const selectSideBarCollapsed = (store: RootState): boolean =>
  store.app.general.sideBarCollapsed;

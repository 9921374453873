import * as yup from "yup";
import { object, string } from "yup";

export const languageOptions = [
  { value: "english", label: "English" },
  { value: "german", label: "German" },
];

export interface CompanyPrivacyPolicyFormValues {
  org_id?: string;
  language: string;
  type: string;
  contentEN: string;
  contentDE: string;
  validUntil?: string;
}

export const schema = object().shape({
  language: string(),
  type: string(),
  contentEN: string(),
  contentDE: string(),
});

import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledFooterContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
});

const StyledFooter = styled("header")({
  backgroundColor: "#151515",
  display: "flex",
  justifyContent: "space-between",
  padding: "10px",
  width: "96%",
  marginInline: "30px",
  alignItems: "center",
});

const StyledSpan = styled("span")({
  fontWeight: 500,
  fontSize: "14px",
  color: "#6F6F6F",
});

const StyledVersion = styled("span")({
  fontWeight: 500,
  fontSize: "9px",
  color: "#E7B14F",
});

const FooterPoweredByBlackpin: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFooterContainer>
      <StyledFooter>
        <StyledVersion>v{process.env.REACT_APP_VERSION}</StyledVersion>
        <StyledSpan>
          {t("general.poweredBy")} <b>BLACKPIN</b>
        </StyledSpan>
      </StyledFooter>
    </StyledFooterContainer>
  );
};

export default FooterPoweredByBlackpin;

import React, { useState } from "react";
import { useAppSelector } from "@hooks";
import {
  selectAppUserData,
  selectFilteredOrganizations,
} from "@Store/AppUser/selectors";
import {
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { RootState } from "@Store";
import {
  selectCurrentOrganization,
  selectIsAdminOfCurrentOrganization,
} from "@Store/Organizations/selectors";

// @ts-ignore
const OrganizationDropdown = ({ onOrgSelect }) => {
  const {
    currentOrganization,
    userData,
    isAdminOfCurrentOrganization,
    organizationList,
  } = useAppSelector((state: RootState) => ({
    currentOrganization: selectCurrentOrganization(state),
    userData: selectAppUserData(state),
    isAdminOfCurrentOrganization: selectIsAdminOfCurrentOrganization(state),
    organizationList: selectFilteredOrganizations(state),
  }));

  const memberships = userData?.memberships || [];
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event: { currentTarget: any }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOrganization = (orgId: string) => {
    onOrgSelect(orgId);
    handleMenuClose();
  };

  // @ts-ignore
  return (
    <div style={{ textAlign: "center", marginBlock: "10px" }}>
      <Button
        variant="contained"
        color="inherit"
        style={{
          backgroundColor: "transparent",
          color: "white",
          border: "1px solid white",
          padding: "7px 10px",
          width: "300px",
        }}
        onClick={(e) => handleMenuOpen(e)}
        endIcon={<ArrowDropDownIcon />}
      >
        {currentOrganization.orgLogoUrl && (
          <img
            src={currentOrganization.orgLogoUrl || ""}
            alt={currentOrganization.orgName}
            height={20}
            style={{ marginRight: 10 }}
          />
        )}
        {currentOrganization.orgName}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {organizationList.map((org) => {
          return (
            <MenuItem
              key={org.orgId}
              onClick={() => handleSelectOrganization(org.orgId)}
            >
              <ListItemIcon>
                {org.orgLogoUrl && (
                  <img
                    src={org.orgLogoUrl || ""}
                    alt={org.orgName}
                    height={20}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary={org.orgName} />
              {currentOrganization.orgId === org.orgId && (
                <FiberManualRecordIcon
                  style={{ height: "10px", color: "green" }}
                />
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default OrganizationDropdown;

import React, { useEffect } from "react";
import {
  Box,
  IconButton,
  Modal,
  Typography,
  TextField,
  MenuItem,
  Button,
  Switch,
  FormControlLabel,
  Select,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { systemUpdateOrganizationRequest } from "@Store/Organizations/actions";
import { updateUserRequest } from "@Store/Users/actions";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

interface CreateEditUserModalProps {
  open: boolean;
  handleClose: () => void;
  initialData?: any;
}

const roleOptions = [
  { value: "SIMPLE_USER", label: i18n.t("user") },
  { value: "ORG_ADMIN", label: i18n.t("orgAdmin") },
];

const validationSchema = Yup.object({
  role: Yup.string().required("Role is required"),
  givenName: Yup.string().required("Firstname is required"),
  surName: Yup.string().required("Lastname is required"),
  department: Yup.string().required("Department is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobileNumber: Yup.string().required("Phone number is required"),
  nickName: Yup.string().nullable(),
  description: Yup.string().nullable(),
  presenceType: Yup.string(),
  presenceDescription: Yup.string(),
  bpRole: Yup.string(),
});

const CreateEditUserModal: React.FC<CreateEditUserModalProps> = ({
  open,
  handleClose,
  initialData,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      role: initialData.role ? initialData.role : "",
      givenName: initialData.givenName ? initialData.givenName : "",
      surName: initialData.surName ? initialData.surName : "",
      nickName: initialData.nickName ? initialData.nickName : "",
      description: initialData.description ? initialData.description : "",
      department: initialData.department ? initialData.department : "",
      emailAddress: initialData.emailAddress ? initialData.emailAddress : "",
      mobileNumber: initialData.mobileNumber ? initialData.mobileNumber : "",
      presenceType: initialData.presenceType,
      presenceDescription: "string",
    },
    validationSchema,
    onSubmit: (values) => {
      // @ts-ignore
      dispatch(
        updateUserRequest({
          ...values,
          presenceType: "",
          presenceDescription: "",
          orgId: initialData.orgId,
          userId: initialData.userId,
        })
      );
      handleClose();
    },
  });

  useEffect(() => {
    if (initialData) {
      formik.setValues({
        role: initialData.role,
        givenName: initialData.givenName || "",
        surName: initialData.surName || "",
        nickName: initialData.nickName || "",
        description: initialData.description || "",
        department: initialData.department || "",
        emailAddress: initialData.emailAddress || "",
        mobileNumber: initialData.mobileNumber || "",
        presenceType: initialData.presenceType,
        presenceDescription: initialData.presenceDescription,
      });
    }
  }, [initialData]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        overflow: "auto",
      }}
    >
      <Box
        className="modal-box"
        sx={{
          bgcolor: "#232323",
          borderRadius: "8px",
          boxShadow: 24,
          width: "400px",
          mx: "auto",
          mt: "10px",
          color: "white",
          p: 4,
        }}
      >
        <div
          className="modal-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #363636",
            padding: "5px 10px",
            marginBottom: "16px",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {initialData?.userId
              ? `${t("buttons.edit")} ${initialData.givenName} ${
                  initialData.surName
                }`
              : "Add New User"}
          </Typography>
          <IconButton onClick={handleClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <Typography>{t("organizationUsers.modals.role")}</Typography>
            <Select
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.role && Boolean(formik.errors.role)}
              sx={{
                color: "white",
                padding: "0",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4C4C4C",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4C4C4C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4C4C4C",
                },
                ".MuiSvgIcon-root": {
                  color: "#4C4C4C",
                },
                ".MuiSelect-select": {
                  padding: "5px",
                },
              }}
            >
              {roleOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    color: "white",
                    backgroundColor: "#3b3b3b",
                    "&:hover": {
                      backgroundColor: "#414141",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#5d5d5d",
                      "&:hover": {
                        backgroundColor: "#5A5A5AFF",
                      },
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="body2" sx={{ mb: 1 }}>
            Firstname:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="givenName"
            value={formik.values.givenName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.givenName && Boolean(formik.errors.givenName)}
            helperText={formik.touched.givenName && formik.errors.givenName}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#4C4C4C" },
              },
              mb: 2,
            }}
          />

          <Typography variant="body2" sx={{ mb: 1 }}>
            Lastname:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="surName"
            value={formik.values.surName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.surName && Boolean(formik.errors.surName)}
            helperText={formik.touched.surName && formik.errors.surName}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#4C4C4C" },
              },
              mb: 2,
            }}
          />

          <Typography variant="body2" sx={{ mb: 1 }}>
            Nickname (optional):
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="nickName"
            value={formik.values.nickName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.nickName && Boolean(formik.errors.nickName)}
            helperText={formik.touched.nickName && formik.errors.nickName}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#4C4C4C" },
              },
              mb: 2,
            }}
          />

          <Typography variant="body2" sx={{ mb: 1 }}>
            Description (optional):
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#4C4C4C" },
              },
              mb: 2,
            }}
          />

          <Typography variant="body2" sx={{ mb: 1 }}>
            Department:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="department"
            value={formik.values.department}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.department && Boolean(formik.errors.department)
            }
            helperText={formik.touched.department && formik.errors.department}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#4C4C4C" },
              },
              mb: 2,
            }}
          />

          <Typography variant="body2" sx={{ mb: 1 }}>
            Email:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="emailAddress"
            value={formik.values.emailAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.emailAddress && Boolean(formik.errors.emailAddress)
            }
            helperText={
              formik.touched.emailAddress && formik.errors.emailAddress
            }
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#4C4C4C" },
              },
              mb: 2,
            }}
          />

          <Typography variant="body2" sx={{ mb: 1 }}>
            Phone nr.:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="mobileNumber"
            value={formik.values.mobileNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)
            }
            helperText={
              formik.touched.mobileNumber && formik.errors.mobileNumber
            }
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#4C4C4C" },
              },
              mb: 2,
            }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              borderRadius: "4px",
            }}
          >
            {t("buttons.save")}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateEditUserModal;

import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
  createTeamRequest,
  createTeamSuccess,
  createTeamFail,
  teamsListRequest,
  teamsListSuccess,
  teamsListFail,
  singleTeamRequest,
  singleTeamSuccess,
  singleTeamFail,
  updateTeamFail,
  updateTeamRequest,
  updateTeamSuccess,
  resetSingleTeam,
} from "./actions";
import { ITeam } from "@API/Teams/types";

interface ITeamsState {
  list: ITeam[];
  isLoading: boolean;
  success: boolean;
  fail: boolean;
  singleTeam: any;
}

const INITIAL_STATE: ITeamsState = {
  list: [],
  isLoading: false,
  success: false,
  fail: false,
  singleTeam: null,
};

const teamsReducer = createReducer(INITIAL_STATE, {
  [teamsListRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [teamsListSuccess.type]: (state, { payload }: PayloadAction<ITeam[]>) => ({
    ...state,
    list: payload,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [teamsListFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),
  [createTeamRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [createTeamSuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [createTeamFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),

  [updateTeamRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [updateTeamSuccess.type]: (state, { payload }: PayloadAction<ITeam>) => ({
    ...state,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [updateTeamFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),

  [singleTeamRequest.type]: (state) => ({
    ...state,
    singleTeam: null,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [singleTeamSuccess.type]: (state, { payload }: any) => {
    return {
      ...state,
      singleTeam: payload,
      isLoading: false,
      success: true,
      fail: false,
    };
  },
  [singleTeamFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),
  [resetSingleTeam.type]: (state) => ({
    ...state,
    singleTeam: null,
  }),
});

export default teamsReducer;

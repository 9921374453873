import React from "react";
import { JSX } from "react/jsx-runtime";

const AddPlusIcon = (
  props: JSX.IntrinsicAttributes &
    React.SVGProps<SVGSVGElement> & { stroke?: string }
) => {
  const { stroke = "black", ...rest } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 1C8.5 0.723858 8.27614 0.5 8 0.5C7.72386 0.5 7.5 0.723858 7.5 1V7.5H1C0.723858 7.5 0.5 7.72386 0.5 8C0.5 8.27614 0.723858 8.5 1 8.5H7.5V15C7.5 15.2761 7.72386 15.5 8 15.5C8.27614 15.5 8.5 15.2761 8.5 15V8.5H15C15.2761 8.5 15.5 8.27614 15.5 8C15.5 7.72386 15.2761 7.5 15 7.5H8.5V1Z"
        fill="black"
      />
    </svg>
  );
};

export default AddPlusIcon;

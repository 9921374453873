import React from "react";
import { Card, CardContent } from "@mui/material";
import InfoLabel from "@Components/InfoLabel/InfoLabel";
import "./styles.scss";

const ContractInfoCard: React.FC = () => (
  <Card className="single-organization-content-card">
    <div className="title">Contract</div>
    <CardContent className="card-content card-content-contract">
      <div className="contract-dates">
        <div style={{ width: "50%" }}>
          <p>
            <span className="info-label">Start:</span> 07.04.2022
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <p>
            <span className="info-label">End:</span> 07.04.2025 / Permanent
          </p>
          <p style={{ paddingTop: "20px" }}>
            <span className="info-label">Termination date:</span> 07.04.2022
          </p>
        </div>
      </div>
      <div className="contract-details contract-details-service-package">
        <InfoLabel label="Service package:" value="Premium, 25€ M" />
        <InfoLabel label="Add ons:" value="Video call, 14€ M" />
      </div>
      <div className="contract-contacts">
        <div className="contact-info">
          <span className="info-label">Contract contact:</span>
          <span className="contact-name">Danielle Stehr</span>
          <span className="contact-email">danielle.stehr@yahoo.com</span>
        </div>
        <div className="contact-info">
          <span className="info-label">Blackpin Sales:</span>
          <span className="contact-name">Markus Blackpinksi</span>
          <span className="contact-email">sales@blackpin.de</span>
        </div>
      </div>
    </CardContent>
  </Card>
);

export default ContractInfoCard;

import { createAction } from "@reduxjs/toolkit";
import { ILoginData, lang } from "./types";

export const loginRequest = createAction<ILoginData>("LOGIN/REQUEST");
export const loginSuccess = createAction<void>("LOGIN/SUCCESS");
export const loginFail = createAction<Error>("LOGIN/FAIL");

export const tokenRefreshRequest = createAction<void>("TOKEN_REFRESH/REQUEST");
export const tokenRefreshSuccess = createAction<void>("TOKEN_REFRESH/SUCCESS");
export const tokenRefreshFail = createAction<Error>("TOKEN_REFRESH/FAIL");

export const logoutRequest = createAction<void>("LOGOUT/REQUEST");
export const logoutSuccess = createAction<void>("LOGOUT/SUCCESS");
export const logoutFail = createAction<Error>("LOGOUT/FAIL");

export const setLanguage = createAction<lang>("SET_LANGUAGE");
export const setSideBarCollapsed = createAction<boolean>(
  "SET_SIDEBAR_COLLAPSED"
);
export const toggleSideBarCollapsed = createAction<void>(
  "TOGGLE_SIDEBAR_COLLAPSED"
);

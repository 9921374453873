import { ReportHandler } from "web-vitals";

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (!onPerfEntry) return;

  import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    const metrics: ((
      onReport: ReportHandler,
      reportAllChanges?: boolean | undefined
    ) => void)[] = [getCLS, getFID, getFCP, getLCP, getTTFB];

    metrics.forEach((metric) => metric(onPerfEntry));
  });
};

export default reportWebVitals;

import React, { useState } from "react";
import { Box } from "@mui/material";
import SingleOrganizationHeader from "@Pages/OrganizationView/SingleOrganizationHeader";
import OrganizationInfoCard from "@Pages/OrganizationView/OrganizationInfoCard";
import ServerInfoCard from "@Pages/OrganizationView/ServerInfoCard";
import AuthorizationCard from "@Pages/OrganizationView/AuthorizationCard";
import ClientInfoCard from "@Pages/OrganizationView/ClientInfoCard";
import ContractInfoCard from "@Pages/OrganizationView/ContractInfoCard";
import "./styles.scss";

const SingleOrganizationView: React.FC = () => {
  const [isActive, setIsActive] = useState(true);

  const handleToggle = () => setIsActive(!isActive);

  return (
    <Box className="root">
      <SingleOrganizationHeader />
      <div className="single-organization-content">
        <div className="single-organization-content-left">
          <OrganizationInfoCard />
          <ServerInfoCard />
          <AuthorizationCard />
        </div>
        <div className="single-organization-content-right">
          <ClientInfoCard />
          <ContractInfoCard />
          {/*<PaymentInvoicesCard />*/}
        </div>
      </div>
    </Box>
  );
};

export default SingleOrganizationView;
